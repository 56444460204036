/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for the role selection

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required.")
    .min(8, "Too Short!")
    .max(60, "Too Long"),
  userName: Yup.string()
    .matches(
      /^[A-Za-z0-9]{3,15}$/,
      "Username must be 3-15 alphanumeric characters."
    )
    .required("Username is required."),
  // email: Yup.string()
  //   .email("Invalid email address.")
  //   .required("Email is required."),

  phoneNumber: Yup.string()
    .matches(/^\d{9,10}$/, "Phone number must be 9 or 10 digits.")
    .required("Phone number is required."),
  password: Yup.string()
    .required("Passwod is required!")
    .min(4, "Too short!")
    .max(16, "Too Long!"),
  roleId: Yup.string().required("Role is required"),
});

const UserMng = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [user, role] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/user"),
        axios.get("http://garment.server.highendtradingplc.com/role"),
      ]);
      setUsers(user.data.filter((u) => u.roleId >= roleData.roleId));
      setRoles(role.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      password: "",
      phoneNumber: "",
      roleId: "",
      email: "",
      id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { userName } = values;
      const userExists = users.find(
        (user) =>
          (user.userName === userName && user.Id !== values.id) ||
          values.phoneNumber === user.phoneNumber
      );
      console.log(values);
      if (userExists) {
        formik.setErrors({
          userName: "Username or phone No already exists.",
        });
        return;
      }

      try {
        if (values.id === "") {
          await axios.post(
            "http://garment.server.highendtradingplc.com/user/add",
            values
          );
          alert("User added successfully!");
          setOpen(false);
        } else {
          const user = users.find((u) => u.Id === parseInt(values.id));
          await axios.put(
            `http://garment.server.highendtradingplc.com/user/update/${values.id}`,
            {
              fullName: values.fullName,
              userName: values.userName,
              phoneNumber: values.phoneNumber,
              roleId: values.roleId,
              // email: values.email,
              // check: 4,
              // verify: user.email === values.email ? user.verfiy : false,
            }
          );
          alert("User Update successfully!");
          setOpen(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        fetchAllData();
      }
    },
  });

  const handleAdd = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleUpdate = (id) => {
    const user = users.find((usr) => usr.Id === parseInt(id));
    formik.setValues({
      fullName: user.fullName,
      userName: user.userName,
      phoneNumber: user.phoneNumber,
      roleId: user.roleId,
      // email: user.email,
      password: "12345",
      id: parseInt(id),
    });
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `http://garment.server.highendtradingplc.com/user/delete/${id}`
      );
      fetchAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const columns = [
    { field: "Id", headerName: "ID", minWidth: 80 },
    { field: "fullName", headerName: "Full Name", minWidth: 150 },
    { field: "userName", headerName: "User Name", minWidth: 150 },
    //  { field: "email", headerName: "Email", minWidth: 150 },
    { field: "phoneNumber", headerName: "Phone Number", minWidth: 150 },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 150,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      minWidth: 150,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "roleId",
      headerName: "Role ID",
      minWidth: 130,
      valueGetter: (params) => roles.find((r) => r.Id === params)?.roleName,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 240,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1000px" }}>
            <div
              className="tab-container"
              style={{ marginTop: "-20px", marginBottom: "-5px" }}
            >
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                User Mgt
              </Link>
              <Link className="p-2" to={`/role_mgt`}>
                Role Mgt
              </Link>
              {/* <Link className="p-2" to={`/account_mgt`}>
            Account Mgt
          </Link> */}
            </div>

            {/* User Management Section */}
            <div className="card card-info card-outline">
              <div className="card-header pl-2">
                {!open && (
                  <Button
                    variant="outline-info"
                    style={{ width: "150px", height: "35px", float: "left" }}
                    onClick={handleAdd}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>
                      Create New
                    </h6>
                  </Button>
                )}
                {open && (
                  <div>
                    <h5 style={{ float: "left" }}>
                      {formik.values.id === "" ? "Create New" : "Update"}{" "}
                      Customer
                    </h5>
                    <Button
                      onClick={handleOpen}
                      variant="outline-success"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <div
                        className="row"
                        style={{
                          marginBottom: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right", marginRight: "-1px" }}
                            icon={faBackwardStep}
                          />
                        </div>
                        <div className="col-7">
                          <h6
                            style={{
                              float: "left",
                              marginLeft: "-10px",
                            }}
                          >
                            Back
                          </h6>
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              <div className="card-body">
                {" "}
                {!open && (
                  <DataGrid
                    rows={users}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    initialState={{
                      ...users.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.Id}
                  />
                )}
                {open && (
                  <center>
                    <Form
                      className="m-2 ml-5 pl-5"
                      onSubmit={formik.handleSubmit}
                    >
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalFullName"
                      >
                        <Col sm={12}>
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="fullName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fullName}
                            error={
                              formik.touched.fullName && formik.errors.fullName
                            }
                            helperText={
                              formik.touched.fullName && formik.errors.fullName
                            }
                            style={{ width: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUserName"
                      >
                        <Col sm={12}>
                          <TextField
                            label="User Name"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="userName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userName}
                            error={
                              formik.touched.userName && formik.errors.userName
                            }
                            helperText={
                              formik.touched.userName && formik.errors.userName
                            }
                            style={{ width: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      {/* <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUserName"
              >
                <Col sm={8}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    style={{ width: "300px" }}
                  />
                </Col>
              </Form.Group> */}
                      {formik.values.id === "" && (
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Col sm={12}>
                            <TextField
                              label="Password"
                              variant="outlined"
                              size="small"
                              type="password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              error={
                                formik.touched.password &&
                                formik.errors.password
                              }
                              helperText={
                                formik.touched.password &&
                                formik.errors.password
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                      )}
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalPhoneNumber"
                      >
                        <Col sm={12}>
                          <TextField
                            label="Phone Number"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="phoneNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                            error={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                            helperText={
                              formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                            }
                            style={{ width: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalRoleId"
                      >
                        <Col sm={12}>
                          <TextField
                            label="Role"
                            variant="outlined"
                            size="small"
                            select
                            name="roleId"
                            fullWidth
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.roleId}
                            error={
                              formik.touched.roleId && !!formik.errors.roleId
                            }
                            helperText={
                              formik.touched.roleId && formik.errors.roleId
                            }
                            style={{ width: "300px" }}
                          >
                            <MenuItem value="">Select Role</MenuItem>
                            {roles.map((role) => {
                              return (
                                role.Id > 1 && (
                                  <MenuItem key={role.Id} value={role.Id}>
                                    {role.roleName}
                                  </MenuItem>
                                )
                              );
                            })}
                          </TextField>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalActions"
                      >
                        <Col sm={12}>
                          <Button
                            type="submit"
                            variant="outline-primary"
                            style={{ width: "300px" }}
                          >
                            {formik.values.id === "" ? "Create" : "Update"}
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </center>
                )}
              </div>
              {/* Data Grid */}
            </div>
          </div>
        </center>
        {/* Navigation Links */}

        {/* Modal for Create/Update User */}
      </main>
    </div>
  );
};

export default UserMng;
