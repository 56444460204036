/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { TextField, Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validationSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Role is required")
    .min(2, "Too Short!")
    .max(25, "Too Long"),
});
const RoleMng = () => {
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const fetchAllData = async () => {
    const [role] = await Promise.all([
      axios.get("http://garment.server.highendtradingplc.com/role"),
    ]);
    setRoles(role.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const formik = useFormik({
    initialValues: {
      roleName: "",
      id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id === "") {
          const { roleName } = values;
          const userExists = roles.find((role) => role?.roleName === roleName);
          if (userExists) {
            formik.setErrors({
              roleName: "Role aame already exists.",
            });
            return;
          }
          await axios.post(
            "http://garment.server.highendtradingplc.com/role/add",
            values
          );
          setOpen(false);
          alert("Role added successfully!");
        } else {
          await axios.put(
            "http://garment.server.highendtradingplc.com/role/update/" +
              values.id,
            values
          );
          setOpen(false);
          alert("Role update successfully!");
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
    },
  });
  const handleUpdate = (id) => {
    const role = roles.find((r) => r.Id === parseInt(id));
    formik.values.roleName = role.roleName;
    formik.values.id = id;
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "http://garment.server.highendtradingplc.com/role/delete/" + id
      );
      fetchAllData();
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      field: "Id",
      headerName: "Id",
      width: 100,
    },
    {
      field: "roleId",
      headerName: "Role Id",

      width: 300,
    },
    {
      field: "roleName",
      headerName: "Role Name",

      width: 200,
    },
    {
      field: "action",
      headerName: "Action",

      width: 240,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
    {
      field: "",
      headerName: "",

      width: 200,
    },

    {
      field: "",
      headerName: "",

      width: 250,
    },
  ];
  const rows = roles?.map((role, i) => {
    return {
      Id: i + 1,
      roleId: role.Id,
      roleName: role.roleName,
    };
  });
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            {" "}
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/user_mgt`}>
                User Mgt
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Role Mgt
              </Link>
              {/* <Link className="p-2" to={`/account_mgt`}>
            Account Mgt
          </Link> */}
            </div>
            <div className="card card-info card-outline">
              <div className="card-header">
                {!open && (
                  <Button
                    variant="outline-info"
                    style={{ width: "150px", height: "35px", float: "left" }}
                    onClick={handleOpen}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>
                      {" "}
                      Create New
                    </h6>
                  </Button>
                )}
              </div>
              <div className="card-body">
                {open && (
                  <div>
                    <Button
                      onClick={handleOpen}
                      style={{ float: "right", height: "30px", width: "30px" }}
                      variant="outline-danger"
                    >
                      <h3
                        style={{
                          float: "left",
                          marginTop: "-8px",
                          marginLeft: "-7px",
                        }}
                      >
                        X
                      </h3>
                    </Button>
                  </div>
                )}
                {!open && (
                  <div
                    className="nav-bra pl-3 ml-1 p-2 "
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px",
                    }}
                  >
                    <span style={{ float: "left" }}> Home / Role List </span>
                  </div>
                )}
                {open && (
                  <div>
                    <div
                      className="nav-bra pl-3 ml-1 p-2"
                      style={{
                        backgroundColor: "rgb(235, 235, 235)",
                      }}
                    >
                      <span>
                        Home / Role /
                        {formik.values.id === "" ? "Create" : "Update"}
                      </span>
                    </div>
                    <hr />
                  </div>
                )}
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                <div style={{ paddingBottom: "10px" }}></div>
                <div>
                  {!open && (
                    <Box
                      sx={{
                        width: "100%",
                        "& .super-app-theme--header": {
                          backgroundColor: "#1de5ec",
                          color: "white",
                        },
                      }}
                    >
                      <DataGrid
                        rows={rows}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={{
                          ...rows.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.Id}
                      />
                    </Box>
                  )}
                  {open && (
                    <center>
                      <Form onSubmit={formik.handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Role Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="roleName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.roleName}
                              error={
                                formik.touched.roleName &&
                                formik.errors.roleName
                              }
                              helperText={
                                formik.touched.roleName &&
                                formik.errors.roleName
                                  ? `${formik.errors.roleName}`
                                  : ""
                              }
                              style={{ minWidth: "200px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ minWidth: "200px" }}
                              variant="outline-success"
                              type="submit"
                            >
                              {formik.values.id === "" ? "Create" : "Update"}
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default RoleMng;
