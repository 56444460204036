/** @format */
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  typeName: Yup.string()
    .required("Type is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  unitName: Yup.string()
    .required("Unit is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  colorName: Yup.string()
    .required("Color is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  name: Yup.string()
    .required("paymentMethod is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
});

const UnitType = () => {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { id } = useParams();

  const fetchAllData = async () => {
    try {
      const [type, unit, color, paymentMethod] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/type"),
        axios.get("http://garment.server.highendtradingplc.com/unit"),
        axios.get("http://garment.server.highendtradingplc.com/color"),
        axios.get("http://garment.server.highendtradingplc.com/paymentMethod"),
      ]);
      setTypes(type.data);
      setUnits(unit.data);
      setColors(color.data);
      setPaymentMethods(paymentMethod.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      typeName: "",
      unitName: "",
      colorName: "",
      name: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      const { name } = values;
      const userExists = paymentMethods.find((pay) => pay?.name === name);
      if (userExists) {
        formik.setErrors({
          name: "Payment Method already exists.",
        });
        return;
      }
      try {
        if (values.id === "") {
          await axios.post(
            `http://garment.server.highendtradingplc.com/${
              parseInt(id) === 1
                ? "type"
                : parseInt(id) === 2
                ? "unit"
                : parseInt(id) === 3
                ? "color"
                : "paymentMethod"
            }/add`,
            values
          );
          setOpen(false);
        } else {
          await axios.put(
            `http://garment.server.highendtradingplc.com/${
              parseInt(id) === 1
                ? "type"
                : parseInt(id) === 2
                ? "unit"
                : parseInt(id) === 3
                ? "color"
                : "paymentMethod"
            }/update/` + values.id,
            values
          );
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        formik.values.typeName = "";
        formik.values.unitName = "";
        formik.values.colorName = "";

        formik.values.name = "";
        formik.values.id = "";
        fetchAllData();
      }
    },
  });

  const handleAdd = () => {
    if (parseInt(id) !== 1) {
      formik.values.typeName = "type";
    }
    if (parseInt(id) !== 2) {
      formik.values.unitName = "unit";
    }
    if (parseInt(id) !== 3) {
      formik.values.colorName = "color";
    }
    if (parseInt(id) !== 4) {
      formik.values.name = "Payment";
    }
    setOpen(true);
  };
  const handleOpen = () => {
    if (parseInt(id) === 1) {
      formik.values.typeName = "";
    }
    if (parseInt(id) === 2) {
      formik.values.unitName = "";
    }
    if (parseInt(id) === 3) {
      formik.values.colorName = "";
    }
    if (parseInt(id) === 4) {
      formik.values.name = "";
    }
    setOpen(false);
  };
  const handleUpdate = (Id) => {
    if (parseInt(id) === 1) {
      const type = types.find((ty) => ty.Id === Id);
      formik.values.typeName = type.typeName;
      formik.values.unitName = "unit";
      formik.values.colorName = "color";
      formik.values.name = "paymentMethod";
      formik.values.id = Id;
    }
    if (parseInt(id) === 2) {
      const unit = units.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = unit.unitName;
      formik.values.colorName = "color";
      formik.values.name = "paymentMethod";
      formik.values.id = Id;
    }
    if (parseInt(id) === 3) {
      const color = colors.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = "unit";
      formik.values.colorName = color.colorName;
      formik.values.name = "paymentMethod";
      formik.values.id = Id;
    }
    if (parseInt(id) === 4) {
      const paymentMethod = paymentMethods.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = "unit";
      formik.values.colorName = "color";

      formik.values.name = paymentMethod.name;
      formik.values.id = Id;
    }
    setOpen(true);
  };
  const handle = () => {
    if (parseInt(id) === 1) {
      formik.values.typeName = "";
    }
    if (parseInt(id) === 2) {
      formik.values.unitName = "";
    }
    if (parseInt(id) === 3) {
      formik.values.colorName = "";
    }
    if (parseInt(id) === 4) {
      formik.values.name = "";
    }
    setOpen(false);
  };
  const handleDelete = async (Id) => {
    try {
      await axios.delete(
        `http://garment.server.highendtradingplc.com/${
          parseInt(id) === 1
            ? "type"
            : parseInt(id) === 2
            ? "unit"
            : parseInt(id) === 3
            ? "color"
            : "paymentMethod"
        }/delete/` + Id
      );
      fetchAllData();
    } catch (error) {}
  };
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  const columnType = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "typeName",
      headerName: "Type Name",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnUnit = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "unitName",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnColor = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "colorName",
      headerName: "Color",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnpaymentMethod = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },

    {
      field: "name",
      headerName: "Payment Method",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="raw_material-page" style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            {parseInt(id) === 4 && (
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-1px",
                }}
              >
                <Link className="p-2" to={`/branch`}>
                  Branch
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={
                    parseInt(id) === 5
                      ? {}
                      : {
                          backgroundColor: "white",
                          borderRadius: "5px 5px 0 0",
                          border: "solid 0.05em rgb(181, 181, 181)",
                        }
                  }
                >
                  Payment Method
                </Link>
              </div>
            )}
            {parseInt(id) !== 4 && (
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-17px",
                }}
              >
                <Link
                  className="p-2"
                  to={`/raw_material`}
                  style={
                    parseInt(id) === 5
                      ? {}
                      : {
                          backgroundColor: "white",
                          borderRadius: "5px 5px 0 0",
                          border: "solid 0.05em rgb(181, 181, 181)",
                        }
                  }
                >
                  Raw Material
                </Link>
                <Link
                  className="p-2"
                  to={`/products`}
                  style={
                    parseInt(id) === 5
                      ? {
                          backgroundColor: "white",
                          borderRadius: "5px 5px 0 0",
                          border: "solid 0.05em rgb(181, 181, 181)",
                        }
                      : {}
                  }
                >
                  Product
                </Link>
                <Link className="p-2" to={`/raw_purchase`}>
                  Raw Purchase
                </Link>
              </div>
            )}
            {parseInt(id) !== 4 && <hr />}
            {parseInt(id) !== 4 && (
              <div
                className="sub-tab-container"
                style={
                  parseInt(id) === 3 ||
                  parseInt(id) === 4 ||
                  parseInt(id) === 5 ||
                  parseInt(id) === 1 ||
                  parseInt(id) === 2
                    ? { marginBottom: "0" }
                    : {}
                }
              >
                <Link
                  className="pr-2 pl-2"
                  to={`/raw_material/types/${parseInt(id) === 5 ? 5 : 1}`}
                  style={parseInt(id) === 1 || parseInt(id) === 5 ? style : {}}
                  onClick={handle}
                >
                  Type
                </Link>
                {parseInt(id) !== 5 && (
                  <Link
                    className="pr-2 pl-2"
                    to={`/raw_material/types/${3}`}
                    style={parseInt(id) === 3 ? style : {}}
                    onClick={handle}
                  >
                    Color
                  </Link>
                )}
                {parseInt(id) !== 5 && (
                  <Link
                    className="pr-2 pl-2"
                    to={`/raw_material/types/${2}`}
                    style={parseInt(id) === 2 ? style : {}}
                    onClick={handle}
                  >
                    Unit
                  </Link>
                )}
              </div>
            )}
            <div className="card card-info card-outline">
              <div className="card-header">
                {!open && (
                  <Button
                    variant="outline-info"
                    style={{ width: "150px", height: "35px", float: "left" }}
                    onClick={handleAdd}
                  >
                    <h3
                      style={{
                        float: "left",
                        marginTop: "-6px",
                        marginRight: "5px",
                      }}
                    >
                      +
                    </h3>
                    <h6 style={{ float: "left", marginTop: "1px" }}>
                      {" "}
                      Create New
                    </h6>
                  </Button>
                )}
                {open && (
                  <div>
                    <h5 style={{ float: "left" }}>
                      {formik.values.id === "" ? "Create New" : "Update"}{" "}
                      Payment Method
                    </h5>
                    <Button
                      onClick={handleOpen}
                      variant="outline-success"
                      className="m-1"
                      style={{ float: "right" }}
                    >
                      <div
                        className="row"
                        style={{
                          marginBottom: "-10px",
                          marginLeft: "-10px",
                        }}
                      >
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right", marginRight: "-1px" }}
                            icon={faBackwardStep}
                          />
                        </div>
                        <div className="col-7">
                          <h6
                            style={{
                              float: "left",
                              marginLeft: "-10px",
                            }}
                          >
                            Back
                          </h6>
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div>
                  <div
                    className="nav-bra pl-3 ml-1 p-2"
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px",
                    }}
                  >
                    {parseInt(id) === 4 ? (
                      <span style={{ float: "left" }}>
                        Payment Method / Home
                      </span>
                    ) : (
                      <span style={{ float: "left" }}>
                        {parseInt(id) === 5 ? "Product" : "Raw Material"} /{" "}
                        {(parseInt(id) === 1 || parseInt(id) === 5) && "Type"}
                        {parseInt(id) === 2 && "Unit"}
                        {parseInt(id) === 3 && "Color"} / Home
                      </span>
                    )}
                  </div>
                  <hr />
                </div>

                {!open && (
                  <DataGrid
                    rows={
                      parseInt(id) === 1
                        ? types
                        : parseInt(id) === 2
                        ? units
                        : parseInt(id) === 3
                        ? colors
                        : paymentMethods
                    }
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={
                      parseInt(id) === 1
                        ? columnType
                        : parseInt(id) === 2
                        ? columnUnit
                        : parseInt(id) === 3
                        ? columnColor
                        : columnpaymentMethod
                    }
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pageSize={5}
                    getRowId={(row) => row.Id}
                  />
                )}

                {open && (
                  <center>
                    <Form
                      className="m-2 ml-5 pl-5"
                      onSubmit={formik.handleSubmit}
                    >
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          {parseInt(id) === 1 && (
                            <TextField
                              id="outlined-basic"
                              label="Type Name"
                              variant="outlined"
                              type="text"
                              size="small"
                              name="typeName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.typeName}
                              error={
                                formik.touched.typeName &&
                                formik.errors.typeName
                              }
                              helperText={
                                formik.touched.typeName &&
                                formik.errors.typeName
                                  ? `${formik.errors.typeName}`
                                  : ""
                              }
                              style={{ width: "250px" }}
                            />
                          )}

                          {parseInt(id) === 3 && (
                            <TextField
                              id="outlined-basic"
                              label="Color Name"
                              variant="outlined"
                              type="text"
                              size="small"
                              name="colorName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.colorName}
                              error={
                                formik.touched.colorName &&
                                formik.errors.colorName
                              }
                              helperText={
                                formik.touched.colorName &&
                                formik.errors.colorName
                                  ? `${formik.errors.colorName}`
                                  : ""
                              }
                              style={{ width: "250px" }}
                            />
                          )}
                          {parseInt(id) === 2 && (
                            <TextField
                              id="outlined-basic"
                              label="Unit Name"
                              variant="outlined"
                              type="text"
                              size="small"
                              name="unitName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.unitName}
                              error={
                                formik.touched.unitName &&
                                formik.errors.unitName
                              }
                              helperText={
                                formik.touched.unitName &&
                                formik.errors.unitName
                                  ? `${formik.errors.unitName}`
                                  : ""
                              }
                              style={{ width: "250px" }}
                            />
                          )}

                          {parseInt(id) === 4 && (
                            <TextField
                              id="outlined-basic"
                              label="Payment Method"
                              variant="outlined"
                              type="text"
                              size="small"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                              error={formik.touched.name && formik.errors.name}
                              helperText={
                                formik.touched.name && formik.errors.name
                                  ? `${formik.errors.name}`
                                  : ""
                              }
                              style={{ width: "250px" }}
                            />
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <Button
                            style={{ width: "250px" }}
                            variant="outline-primary"
                            type="submit"
                          >
                            {formik.values.id === "" ? "Create" : "Update"}
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </center>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default UnitType;
