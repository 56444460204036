import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackwardStep,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const validationSchema = Yup.object().shape({
  startedDate: Yup.date().required("Start Date is required!"),
  endingDate: Yup.date().required("End Date is required!"),
});
const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
  Id: 0,
  roleId: 0,
};
const ProductionPage = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [productionOrderRefs, setProductionOrderRefs] = useState([]);
  const [newProductionOrder, setNewProductionOrde] = useState([]);
  const [types, setTypes] = useState([]);
  const [pros, setPros] = useState([]);
  const fetchAllData = async () => {
    try {
      const [
        user,
        productionOrder,
        productionOrderRef,
        product,
        customer,
        production,
        rawOrder,
        type,
      ] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/user"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrderRef"
        ),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/customer"),
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get("http://garment.server.highendtradingplc.com/rawOrderRef"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
      ]);
      setTypes(type.data);
      setUsers(user.data);
      let data = [];
      production.data.map((pp) => {
        if (!!!pp?.endingDate && !!pp?.materialReceiveDate) {
          data.push(pp);
        }
      });

      setProductionOrders(productionOrder.data);
      setProductionOrderRefs(productionOrderRef.data);
      setCustomers(customer.data);
      setProducts(product.data);
      setProductions(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const formik = useFormik({
    initialValues: {
      startedDate: "",
      endingDate: "",
      productionOrderId: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        console.log(values);
        await axios.post(
          "http://garment.server.highendtradingplc.com/production/add",
          values
        );
        //  alert("Date added successfully!");
        setOpen(false);
      } catch (error) {
        console.error(error);
      } finally {
        fetchAllData();
      }
    },
  });

  const handleView = (row) => {
    const order = productionOrders.filter(
      (p) => p.productionOrderRefId === row.id
    );
    setNewProductionOrde(order);
    setPros(row);
    setOpen(true);
  };

  const columns = [
    { field: "Id", headerName: "ID", width: 50 },
    { field: "fullName", headerName: "FullName", width: 150 },
    {
      field: "receiveDate",
      headerName: "Order Recieve Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "deadlineDate",
      headerName: "Order Deadline Date",
      minWidth: 145,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "start",
      headerName: "Start Date",
      minWidth: 110,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
    {
      field: "end",
      headerName: "Raw Receive Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
  ];
  const rows = productions?.map((production, index) => {
    const orders = productionOrderRefs.find(
      (order) => order.Id === production.productionOrderRefId
    );
    const customer = customers.find((c) => c.Id == orders.customerId);

    return {
      Id: index + 1,
      id: orders?.Id,
      productionId: production?.Id,
      fullName: customer?.fullName,
      receiveDate: orders?.orderReceiveDate,
      deadlineDate: orders?.orderDeadlineDate,
      start: production?.startedDate || "",
      end: production?.materialReceiveDate || "",
    };
  });

  return (
    <div>
      <Header />
      <main style={{ marginTop: "180px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div className="card card-info card-outline">
              <div className="card-header">
                <h4 style={{ float: "left" }}>New Production Entry</h4>
                {open && (
                  <Button
                    onClick={(e) => {
                      setOpen(false);
                    }}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                )}
              </div>
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}> Production / Home</span>
                </div>
                <hr />
                {!open && (
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    initialState={{
                      ...users.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    onCellClick={(row) => handleView(row.row)}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.Id}
                  />
                )}
                {open && (
                  <div className="row">
                    {newProductionOrder.map((rawOrder) => (
                      <div className="col-md-6 col-lg-4 mb-2 ">
                        <div
                          className="row p-3"
                          style={{
                            width: "290px",
                            height: "250px",
                            borderRadius: "10px",
                            background: "rgb(235, 235, 235)",
                          }}
                        >
                          <div className="mt-3 col-6">
                            <img
                              src={`http://garment.server.highendtradingplc.com/${
                                products.find(
                                  (p) => p.Id === rawOrder.productId
                                )?.photo
                              }`}
                              style={{
                                width: "120px",
                                height: "120px",
                              }}
                              alt="Photo"
                            />
                          </div>
                          <div className="mt-2 mb-3  col-6" style={{}}>
                            <div style={{ float: "left" }}>
                              {" "}
                              <b>Scale-1:</b> <span>{rawOrder.scale1}</span>
                            </div>
                            <br />
                            <div style={{ float: "left" }}>
                              {" "}
                              <b>Scale-2:</b> <span>{rawOrder.scale2}</span>
                            </div>
                            <br />
                            <div style={{ float: "left" }}>
                              {" "}
                              <b>Scale-3:</b> <span>{rawOrder.scale3}</span>
                            </div>
                            <br />
                            <div style={{ float: "left" }}>
                              {" "}
                              <b>Scale-4:</b> <span>{rawOrder.scale4}</span>
                            </div>
                            {!!products.find(
                              (p) =>
                                p.Id === rawOrder.productId &&
                                (p.typeId === 2 || p.typeId === 1)
                            ) && (
                              <>
                                <br />
                                <div style={{ float: "left" }}>
                                  <b>Scale-5:</b> <span>{rawOrder.scale5}</span>
                                </div>
                              </>
                            )}
                            {!!products.find(
                              (p) =>
                                p.Id === rawOrder.productId && p.typeId === 2
                            ) && (
                              <>
                                <br />
                                <div style={{ float: "left" }}>
                                  <b>Scale-6:</b> <span>{rawOrder.scale6}</span>
                                </div>
                              </>
                            )}
                            <br />
                            <div style={{ float: "left" }}>
                              {" "}
                              <b>Quantity:</b> <span>{rawOrder.quantity}</span>
                            </div>
                          </div>
                          <div style={{ float: "none" }} className="">
                            {!!products.find(
                              (p) =>
                                p.Id === rawOrder.productId && p.typeId === 3
                            ) && (
                              <>
                                <br />
                              </>
                            )}
                            <div
                              style={{
                                float: "left",
                                marginTop: "-20px",
                              }}
                              className=""
                            >
                              <b className="">Name:</b>
                              <span className="">
                                {
                                  products.find(
                                    (p) => p.Id === rawOrder.productId
                                  )?.name
                                }
                              </span>
                            </div>
                            <br />
                            <div
                              style={{
                                float: "left",
                                marginTop: "-20px",
                              }}
                              className=""
                            >
                              <b className="">Type:</b>{" "}
                              <span className="">
                                {
                                  types.find(
                                    (t) =>
                                      t.Id ===
                                      products.find(
                                        (p) => p.Id === rawOrder.productId
                                      )?.typeId
                                  )?.typeName
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {open && (
                  <div className="">
                    <center>
                      <div>
                        <Button
                          onClick={async (e) => {
                            console.log(pros);
                            await axios.put(
                              "http://garment.server.highendtradingplc.com/production/update/" +
                                pros.productionId,
                              {
                                endingDate: new Date(),
                              }
                            );
                            alert("Data send successfully!");
                            fetchAllData();
                            setOpen(false);
                          }}
                          variant="outline-success"
                          className="m-1"
                        >
                          <div
                            className="row"
                            style={{
                              marginBottom: "-10px",
                              marginLeft: "-10px",
                            }}
                          >
                            <div className="col">
                              <FontAwesomeIcon
                                style={{
                                  float: "right",
                                  marginRight: "-5px",
                                }}
                                icon={faPaperPlane}
                              />
                            </div>
                            <div className="col-7">
                              <h6
                                style={{
                                  float: "left",
                                  marginLeft: "-3px",
                                }}
                              >
                                Send
                              </h6>
                            </div>
                          </div>
                        </Button>
                      </div>
                    </center>
                  </div>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default ProductionPage;
