/** @format */

// import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./page/HomePage";
import CustomerPage from "./page/CustomerPage";
import VendorPage from "./page/VendorPage";
import RawMatrialPage from "./page/RawMaterialPage";
import ProductsPage from "./page/ProductsPage";
import RawPurchasePage from "./page/RawPurchasePage";
import RawPurchaseReport from "./page/RawPurchaseReport";
import SalesReport from "./page/SalesReport";
import UserMng from "./page/UserMng";
import RoleMng from "./page/RoleMng";
import UnitType from "./page/UnitType";
import Login from "./Login";
import ProductionOrderReport from "./page/ProductionOrderReport";
import ProductionPage from "./page/ProductionPage";
import ProductionOrderPage from "./page/ProductionOrderPage";
import SalesPage from "./page/SalesPage";
import QualityCheckerPage from "./page/QualityCheckerPage";
import Branch from "./page/Branch";
import QualityCheckerReport from "./page/QualityCheckerReport";
import RawOrder from "./page/RawOrder";
import RawOrderReport from "./page/RawOrderReport";
import { useEffect } from "react";
const Home = () => {
  const RedirectLoation = () => {
    const oldapi = JSON.parse(window.sessionStorage.getItem("api")) || {
      api: `/`,
    };
    const navigate = useNavigate();
    useEffect(() => {
      navigate(oldapi.api);
    }, []);
  };
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/user" element={<UserMng />} />
        {roleData.roleId === 1 ? (
          <>
            <Route path="/user_mgt" element={<UserMng />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : roleData.roleId === 2 ? (
          <>
            <Route path="/branch" element={<Branch />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/production" element={<ProductionPage />} />
            <Route path="/raw_order/:id" element={<RawOrder />} />
            <Route path="/raw_order_report" element={<RawOrderReport />} />
            <Route path="/vendor" element={<VendorPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/raw_material" element={<RawMatrialPage />} />
            <Route path="/raw_purchase" element={<RawPurchasePage />} />
            <Route
              path="/raw_purchase_report"
              element={<RawPurchaseReport />}
            />
            <Route path="/raw_material/types/:id" element={<UnitType />} />
            <Route path="/customers" element={<CustomerPage />} />
            <Route path="/production_order" element={<ProductionOrderPage />} />
            <Route
              path="/production_order_report"
              element={<ProductionOrderReport />}
            />
            <Route
              path="/quality_checker_report"
              element={<QualityCheckerReport />}
            />
            <Route path="/quality_checker" element={<QualityCheckerPage />} />
            <Route path="/sales_report" element={<SalesReport />} />
            <Route path="/sales" element={<SalesPage />} />
            <Route path="/user_mgt" element={<UserMng />} />
            <Route path="/role_mgt" element={<RoleMng />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : roleData.roleId === 3 ? (
          <>
            <Route path="/home" element={<HomePage />} />
            <Route path="/customers" element={<CustomerPage />} />
            <Route
              path="/production_order"
              element={<ProductionOrderPage />}
            />{" "}
            <Route
              path="/production_order_report"
              element={<ProductionOrderReport />}
            />
            <Route
              path="/quality_checker_report"
              element={<QualityCheckerReport />}
            />
            <Route path="/quality_checker" element={<QualityCheckerPage />} />
            <Route path="/sales_report" element={<SalesReport />} />
            <Route path="/sales" element={<SalesPage />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : roleData.roleId === 4 ? (
          <>
            <Route
              path="/quality_checker_report"
              element={<QualityCheckerReport />}
            />
            <Route path="/quality_checker" element={<QualityCheckerPage />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : roleData.roleId === 5 ? (
          <>
            <Route path="/home" element={<HomePage />} />
            <Route path="/vendor" element={<VendorPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/raw_material" element={<RawMatrialPage />} />
            <Route path="/raw_purchase" element={<RawPurchasePage />} />
            <Route
              path="/raw_purchase_report"
              element={<RawPurchaseReport />}
            />
            <Route path="/raw_order" element={<RawOrder />} />
            <Route path="/raw_material/types/:id" element={<UnitType />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : roleData.roleId === 6 ? (
          <>
            <Route path="/home" element={<HomePage />} />
            <Route path="/production" element={<ProductionPage />} />
            <Route path="/raw_order" element={<RawOrder />} />
            <Route path="/raw_order_report" element={<RawOrderReport />} />
            <Route path="/logout" element={<RedirectLoation />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<RedirectLoation />} />
          </>
        )}
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
