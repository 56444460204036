/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required.")
    .min(4, "Too Short!")
    .max(60, "Too Long"),
  phoneNumber: Yup.string()
    .matches(/^\d{9,10}$/, "Phone number must be 9 or 10 digits.")
    .required("Phone number is required."),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
});
const CustomerPage = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);

  const fetchAllData = async () => {
    try {
      const [customer] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/customer"),
      ]);
      setCustomers(customer.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      address: "",
      phoneNumber: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id === "") {
          const { phoneNumber } = values;
          const userExists = customers.find(
            (customer) => customer?.phoneNumber === phoneNumber
          );
          if (userExists) {
            formik.setErrors({
              phoneNumber: "Phone number already exists.",
            });
            return;
          }
          if (
            !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
          ) {
            formik.setErrors({
              tinNumber: "Till number must be empty or exactly 10 digits.",
            });
            return;
          }
          await axios.post(
            "http://garment.server.highendtradingplc.com/customer/add",
            values
          );
          setOpen(false);
        } else {
          await axios.put(
            "http://garment.server.highendtradingplc.com/customer/update/" +
              values.id,
            values
          );
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
    },
  });
  const handleAdd = () => {
    formik.values.address = "";
    formik.values.fullName = "";
    formik.values.phoneNumber = "";
    formik.values.tinNumber = "";
    formik.values.id = "";
    setOpen(true);
  };
  const handleUpdate = (id) => {
    const customer = customers.find((cust) => cust.Id === parseInt(id));
    formik.values.address = customer.address;
    formik.values.fullName = customer.fullName;
    formik.values.phoneNumber = customer.phoneNumber;
    formik.values.tinNumber = customer.tinNumber;
    formik.values.id = parseInt(id);
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "http://garment.server.highendtradingplc.com/customer/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "fullName",
      headerAlign: "center",
      align: "center",
      headerName: "Customer Name",
      minWidth: 150,
    },
    {
      field: "tinNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Tin Number",
      minWidth: 120,
    },
    {
      field: "phoneNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Phone Number",
      minWidth: 150,
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      minWidth: 120,
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      minWidth: 240,

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div className="">
              <div className="card card-info card-outline">
                <div className="card-header pl-2">
                  {!open && (
                    <Button
                      variant="outline-info"
                      style={{ width: "150px", height: "35px", float: "left" }}
                      onClick={handleAdd}
                    >
                      <h3
                        style={{
                          float: "left",
                          marginTop: "-6px",
                          marginRight: "5px",
                        }}
                      >
                        +
                      </h3>
                      <h6 style={{ float: "left", marginTop: "1px" }}>
                        {" "}
                        Create New
                      </h6>
                    </Button>
                  )}
                  {open && (
                    <div>
                      <h5 style={{ float: "left" }}>
                        {formik.values.id === "" ? "Create New" : "Update"}{" "}
                        Customer
                      </h5>
                      <Button
                        onClick={handleOpen}
                        variant="outline-success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Back
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div>
                    {!open && (
                      <div>
                        <div
                          className="nav-bra pl-3 ml-1 p-2"
                          style={{
                            backgroundColor: "rgb(235, 235, 235)",
                            height: "40px",
                          }}
                        >
                          <span style={{ float: "left" }}>
                            Home / Customer{" "}
                          </span>
                        </div>
                        <hr />
                      </div>
                    )}
                    {open && (
                      <div>
                        <div
                          className="nav-bra pl-3 ml-1 p-2"
                          style={{
                            backgroundColor: "rgb(235, 235, 235)",
                          }}
                        >
                          <span>
                            Home / Customer /{" "}
                            {formik.values.id === "" ? "Create" : "Update"}{" "}
                          </span>
                        </div>
                        <hr />
                      </div>
                    )}
                    {!open && (
                      <DataGrid
                        rows={customers}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        initialState={{
                          ...customers.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.Id}
                      />
                    )}
                  </div>

                  {open && (
                    <center>
                      <div>
                        <Form
                          className="m-2 ml-5 pl-5"
                          onSubmit={formik.handleSubmit}
                        >
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="fullName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fullName}
                                error={
                                  formik.touched.fullName &&
                                  formik.errors.fullName
                                }
                                helperText={
                                  formik.touched.fullName &&
                                  formik.errors.fullName
                                    ? `${formik.errors.fullName}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Till Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="tinNumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.tinNumber}
                                error={
                                  formik.touched.tinNumber &&
                                  formik.errors.tinNumber
                                }
                                helperText={
                                  formik.touched.tinNumber &&
                                  formik.errors.tinNumber
                                    ? `${formik.errors.tinNumber}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Phone Number"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="phoneNumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phoneNumber}
                                error={
                                  formik.touched.phoneNumber &&
                                  formik.errors.phoneNumber
                                }
                                helperText={
                                  formik.touched.phoneNumber &&
                                  formik.errors.phoneNumber
                                    ? `${formik.errors.phoneNumber}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                error={
                                  formik.touched.address &&
                                  formik.errors.address
                                }
                                helperText={
                                  formik.touched.address &&
                                  formik.errors.address
                                    ? `${formik.errors.address}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col sm={12}>
                              <Button
                                style={{ width: "300px" }}
                                variant="outline-success"
                                type="submit"
                              >
                                {formik.values.id === "" ? "Create" : "Update"}
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default CustomerPage;
