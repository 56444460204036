import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Button, Form, Col, Row } from "react-bootstrap";
import { Autocomplete, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faBackwardStep,
  faFloppyDisk,
  faTrashCan,
  faList,
  faL,
} from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const RawOrder = () => {
  const [rawMaterials, setRawMaterials] = useState([]);
  const [products, setProducts] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [valids, setValid] = useState([0, 0]);
  const [rawMaterialValue, setRawMaterialValue] = useState([]);
  const [rawMaterialId, setRawMaterialId] = useState(null);
  const [rawMaterialCheck, setRawMaterialCheck] = useState(null);
  const [open, setOpen] = useState(false);
  const [rawOrders, setRawOrders] = useState([]);
  const [types, setTypes] = useState([]);
  const [colors, setColors] = useState([]);
  const [units, setUnits] = useState([]);
  const [openOrder, setOpenOrder] = useState(false);
  const [orderRefs, setOrderRefs] = useState([]);
  const [newRawOrder, setNewRawOrder] = useState([]);
  const [productions, setProductions] = useState([]);
  const [order, setOrder] = useState([]);
  const [pop, setPop] = useState(false);
  const [allProduction, setAllProduction] = useState([]);
  const [viewOrder, setViewOrder] = useState(false);
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const { id } = useParams();
  const fetchAllData = async () => {
    try {
      const [
        productionOrder,
        rawMaterial,
        product,
        type,
        unit,
        color,
        rawOrderRef,
        rawOrder,
        production,
        productionOrderRef,
      ] = await Promise.all([
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("http://garment.server.highendtradingplc.com/rawMaterial"),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
        axios.get("http://garment.server.highendtradingplc.com/unit"),
        axios.get("http://garment.server.highendtradingplc.com/color"),
        axios.get("http://garment.server.highendtradingplc.com/rawOrderRef"),
        axios.get("http://garment.server.highendtradingplc.com/rawOrder"),
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrderRef"
        ),
      ]);
      let data = [];
      productionOrderRef.data.map((p) => {
        const h = rawOrderRef?.data?.find(
          (q) => q.productionOrderRefId === p.Id
        );
        const g = production?.data?.find(
          (t) => t.productionOrderRefId === p.Id
        );
        if (!!!h && !!!g) {
          data.push(p);
        }
      });
      setAllProduction(productionOrder.data);
      setProductions(production.data);
      setOrderRefs(rawOrderRef.data.filter((r) => r.statusId === 1));
      setNewRawOrder(rawOrder.data);
      setProductionOrders(data);
      setRawMaterials(rawMaterial.data);
      setProducts(product.data);
      setTypes(type.data);
      setUnits(unit.data);
      setColors(color.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const rawMaterialData = {
    options: rawMaterials,
    getOptionLabel: (option) => option.name,
  };

  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };
  const clearData = () => {
    setRawMaterialValue([]);
  };
  const handleSubmits = async () => {
    try {
      const Id = rawOrders.find((r) => r.Id > 0);
      await axios
        .post("http://garment.server.highendtradingplc.com/rawOrderRef/add", {
          reqdate: new Date(),
          recdate: new Date(),
          productionOrderRefId: Id.productionOrderRefId,
        })
        .then(async (res) => {
          console.log(res);
          for (let i = 0; i <= rawMaterialValue.length - 1; ) {
            await axios.post(
              "http://garment.server.highendtradingplc.com/rawOrder/add",
              {
                rawMaterialId: rawMaterialValue[i].rawMaterialId,
                rawOrderRefId: res.data.data.Id,
                quantity: rawMaterialValue[i].quantity,
              }
            );

            i = i + 1;
          }
          await axios.post(
            "http://garment.server.highendtradingplc.com/production/add",
            {
              productionOrderRefId: Id.productionOrderRefId,
              startedDate: new Date(),
            }
          );
        });
    } catch (error) {
      console.log(error);
    } finally {
      alert("Data insert successfully!");
      fetchAllData();
      setOpen(false);
      setOpenOrder(false);
    }
  };
  const handleOrder = (Id) => {
    if (parseInt(id) === 1) {
      setOpen(true);
      const orders = allProduction.filter((a) => a.productionOrderRefId === Id);
      setRawOrders(orders);
      return;
    }
    const rawO = newRawOrder.filter((r) => r.rawOrderRefId === Id);
    setOrder(rawO);
    const raw = orderRefs.find(
      (r) => r.Id === rawO.find((rr) => rr.Id > 0)?.rawOrderRefId
    );
    setPop(true);
    const orders = allProduction.filter(
      (a) => a.productionOrderRefId === raw.productionOrderRefId
    );
    setRawOrders(orders);
    console.log(raw, orders);
  };

  const columns = [
    { field: "id", headerName: "#", minWidth: 80 },
    { field: "Id", headerName: "Id", minWidth: 150 },
    {
      field: "deadlineDate",
      headerName: "Order Deadline Date",
      minWidth: 150,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "receiveDate",
      headerName: "Order Request Date",
      minWidth: 150,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnsraw = [
    { field: "id", headerName: "#", width: 80 },
    { field: "Id", headerName: "Raw Order Ref Id", minWidth: 120 },
    {
      field: "date",
      headerName: "Request Date",
      minWidth: 150,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnsOrder = [
    { field: "id", headerName: "#", width: 60 },
    { field: "Id", headerName: "Id", width: 60 },
    { field: "name", headerName: "Raw Materail", width: 100 },
    { field: "quantity", headerName: "Request Qty", width: 100 },
    { field: "type", headerName: "Type", width: 80 },
    { field: "color", headerName: "Color", width: 80 },
    { field: "unit", headerName: "Unit", width: 80 },
    { field: "qty", headerName: "Remain Qty", width: 100 },
  ];

  const rows = productionOrders?.map((productionOrder, index) => {
    return {
      id: index + 1,
      Id: productionOrder?.Id,
      deadlineDate: productionOrder?.orderDeadlineDate,
      receiveDate: productionOrder?.orderReceiveDate,
    };
  });
  const rowsRaw = orderRefs.map((r, i) => {
    return {
      id: i + 1,
      Id: r?.Id,
      date: r?.reqdate,
      productionOrderRefId: r?.productionOrderRefId,
    };
  });
  const rowsOrder = order?.map((r, i) => {
    const rawMaterial = rawMaterials.find((m) => m.Id === r.rawMaterialId);
    const type = types.find((t) => t.Id === rawMaterial.typeId);
    const color = colors.find((c) => c.Id === rawMaterial.colorId);
    const unit = units.find((u) => u.Id === rawMaterial.unitId);
    const rawRef = orderRefs.find((o) => o.Id === r.rawOrderRefId);
    return {
      id: i + 1,
      Id: r?.Id,
      name: rawMaterial?.name,
      type: type?.typeName,
      quantity: r?.quantity,
      qty: rawMaterial?.quantity,
      color: color?.colorName,
      unit: unit?.unitName,
      date: rawRef?.reqdate,
    };
  });

  return (
    <div>
      <Header />
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            {parseInt(id) === 1 && (
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-5px",
                }}
              >
                <Link className="p-2" to={`/production_order`}>
                  Production Order
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Order List
                </Link>
              </div>
            )}
            {(roleData.roleId === 6 || roleData.roleId === 2) &&
              parseInt(id) === 1 && (
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {open && (
                      <Button
                        onClick={(e) => {
                          setOpen(false);
                          setOpenOrder(false);
                        }}
                        variant="outline-success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Back
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <div className="card-body">
                    {!open && (
                      <div>
                        <div
                          className="nav-bra pl-3 ml-1 p-2"
                          style={{
                            backgroundColor: "rgb(235, 235, 235)",
                            height: "40px",
                          }}
                        >
                          <span style={{ float: "left" }}>
                            Raw Order / Home / Create
                          </span>
                        </div>
                        <hr />
                      </div>
                    )}
                    {!open && (
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        initialState={{
                          ...rows.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        onCellClick={(raw) => handleOrder(raw.id)}
                        pageSizeOptions={[5, 10, 15]}
                        getRowId={(row) => row.Id}
                      />
                    )}
                    {open && (
                      <div>
                        <div
                          className="nav-bra pl-3 ml-1 p-2 "
                          style={{
                            backgroundColor: "rgb(235, 235, 235)",
                          }}
                        >
                          <span>Raw Order / Create</span>
                        </div>
                        <hr />
                        <div>
                          <div>
                            <div className=" ml-1 mr-1">
                              <div className="row">
                                {rawOrders.map((rawOrder) => (
                                  <div className="col-md-6 col-lg-4 mb-2 ">
                                    <div
                                      className="row p-3"
                                      style={{
                                        width: "290px",
                                        height: "250px",
                                        borderRadius: "10px",
                                        background: "rgb(235, 235, 235)",
                                      }}
                                    >
                                      <div className="mt-3 col-6">
                                        <img
                                          src={`http://garment.server.highendtradingplc.com/${
                                            products.find(
                                              (p) => p.Id === rawOrder.productId
                                            ).photo
                                          }`}
                                          style={{
                                            width: "120px",
                                            height: "120px",
                                          }}
                                          alt="Photo"
                                        />
                                      </div>
                                      <div
                                        className="mt-2 mb-3  col-6"
                                        style={{}}
                                      >
                                        <div style={{ float: "left" }}>
                                          {" "}
                                          <b>Scale-1:</b>{" "}
                                          <span>{rawOrder.scale1}</span>
                                        </div>
                                        <br />
                                        <div style={{ float: "left" }}>
                                          {" "}
                                          <b>Scale-2:</b>{" "}
                                          <span>{rawOrder.scale2}</span>
                                        </div>
                                        <br />
                                        <div style={{ float: "left" }}>
                                          {" "}
                                          <b>Scale-3:</b>{" "}
                                          <span>{rawOrder.scale3}</span>
                                        </div>
                                        <br />
                                        <div style={{ float: "left" }}>
                                          {" "}
                                          <b>Scale-4:</b>{" "}
                                          <span>{rawOrder.scale4}</span>
                                        </div>
                                        {!!products.find(
                                          (p) =>
                                            p.Id === rawOrder.productId &&
                                            (p.typeId === 2 || p.typeId === 1)
                                        ) && (
                                          <>
                                            <br />
                                            <div style={{ float: "left" }}>
                                              <b>Scale-5:</b>{" "}
                                              <span>{rawOrder.scale5}</span>
                                            </div>
                                          </>
                                        )}
                                        {!!products.find(
                                          (p) =>
                                            p.Id === rawOrder.productId &&
                                            p.typeId === 2
                                        ) && (
                                          <>
                                            <br />
                                            <div style={{ float: "left" }}>
                                              <b>Scale-6:</b>{" "}
                                              <span>{rawOrder.scale6}</span>
                                            </div>
                                          </>
                                        )}
                                        <br />
                                        <div style={{ float: "left" }}>
                                          {" "}
                                          <b>Quantity:</b>{" "}
                                          <span>{rawOrder.quantity}</span>
                                        </div>
                                      </div>
                                      <div
                                        style={{ float: "none" }}
                                        className=""
                                      >
                                        {!!products.find(
                                          (p) =>
                                            p.Id === rawOrder.productId &&
                                            p.typeId === 3
                                        ) && (
                                          <>
                                            <br />
                                          </>
                                        )}
                                        <div
                                          style={{
                                            float: "left",
                                            marginTop: "-20px",
                                          }}
                                          className=""
                                        >
                                          <b className="">Name:</b>
                                          <span className="">
                                            {
                                              products.find(
                                                (p) =>
                                                  p.Id === rawOrder.productId
                                              )?.name
                                            }
                                          </span>
                                        </div>
                                        <br />
                                        <div
                                          style={{
                                            float: "left",
                                            marginTop: "-20px",
                                          }}
                                          className=""
                                        >
                                          <b className="">Type:</b>{" "}
                                          <span className="">
                                            {
                                              types.find(
                                                (t) =>
                                                  t.Id ===
                                                  products.find(
                                                    (p) =>
                                                      p.Id ===
                                                      rawOrder.productId
                                                  )?.typeId
                                              )?.typeName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="row">
                                {!openOrder && (
                                  <div className="mt-2 col">
                                    <Button
                                      onClick={(e) => {
                                        setOpenOrder(true);
                                      }}
                                      variant="success"
                                    >
                                      Raw Order
                                    </Button>
                                  </div>
                                )}
                                {!openOrder && (
                                  <div className="mt-2 col">
                                    <Button
                                      onClick={async (e) => {
                                        console.log();
                                        await axios.post(
                                          "http://garment.server.highendtradingplc.com/production/add",
                                          {
                                            startedDate: new Date(),
                                            materialReceiveDate: new Date(),
                                            productionOrderRefId:
                                              rawOrders[0].productionOrderRefId,
                                          }
                                        );
                                        alert("Data send successfully!");
                                        fetchAllData();
                                        setOpen(false);
                                        setOpenOrder(false);
                                      }}
                                      variant="primary"
                                    >
                                      Raw Available
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            {openOrder && (
                              <div className="row ml-2">
                                <div className="col">
                                  <hr />
                                  <div
                                    className="row "
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <div className="col-1">
                                      <FontAwesomeIcon
                                        style={{ float: "right" }}
                                        icon={faList}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-10px",
                                        }}
                                      >
                                        Raw Order Raw Material List
                                      </h6>
                                    </div>
                                  </div>
                                  <hr />
                                  <Formik
                                    initialValues={{
                                      rawMaterialId: "",
                                      quantity: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                      quantity: Yup.number()
                                        .required("Quantity is required.")
                                        .min(0, "Too Short!")
                                        .max(1000000, "Too Long"),
                                      rawMaterialId: Yup.string().required(
                                        "Raw Material is required."
                                      ),
                                    })}
                                    onSubmit={async (values) => {
                                      try {
                                        if (rawMaterialCheck === null) {
                                          return;
                                        }
                                        console.log(values);
                                        const checkItem =
                                          rawMaterialValue?.find(
                                            (i) =>
                                              i?.rawMaterialId ===
                                              rawMaterialId.Id
                                          );
                                        if (!!checkItem) {
                                          alert(
                                            "You use the same rawMaterial!"
                                          );
                                          setRawMaterialId(null);
                                          setRawMaterialCheck(0);
                                          return;
                                        }
                                        setRawMaterialValue([
                                          ...rawMaterialValue,
                                          {
                                            rawMaterialId: values.rawMaterialId,
                                            quantity: values.quantity,
                                          },
                                        ]);
                                        setRawMaterialCheck(0);
                                        setRawMaterialId(null);
                                      } catch (error) {
                                        console.log(error);
                                      } finally {
                                        values.quantity = "";
                                      }
                                    }}
                                  >
                                    {(props) => (
                                      <div className="">
                                        <Form onSubmit={props.handleSubmit}>
                                          <Form.Group
                                            as={Row}
                                            controlId="formHorizontalUsername"
                                          >
                                            <Col lg={6} className="mb-3">
                                              <Autocomplete
                                                {...rawMaterialData}
                                                id="controlled-demo"
                                                size="small"
                                                name="rawMaterialId"
                                                className="ml-2"
                                                value={rawMaterialId}
                                                onBlur={(e) =>
                                                  handleValidity(1)
                                                }
                                                onChange={(event, newValue) => {
                                                  if (!newValue?.Id) {
                                                    setRawMaterialCheck(null);
                                                    return;
                                                  }
                                                  setRawMaterialId(newValue);
                                                  //   props.values.taxId = newValue?.taxId;
                                                  props.values.rawMaterialId =
                                                    newValue?.Id;
                                                  setRawMaterialCheck(5);
                                                }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    style={{
                                                      width: "250px",
                                                      float: "left",
                                                    }}
                                                    label={
                                                      <div
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <span>
                                                          Select Raw Material
                                                        </span>
                                                      </div>
                                                    }
                                                    error={
                                                      valids[1] === 1 &&
                                                      rawMaterialCheck === null
                                                    }
                                                  />
                                                )}
                                              />
                                            </Col>
                                            <Col lg={6}>
                                              {" "}
                                              <TextField
                                                className="mb-2 ml-2"
                                                id="outlined-basic"
                                                label="Qty"
                                                variant="outlined"
                                                type="text"
                                                size="small"
                                                name="quantity"
                                                onChange={props.handleChange}
                                                value={props.values.quantity}
                                                onBlur={props.handleBlur}
                                                error={
                                                  props.touched.quantity &&
                                                  props.errors.quantity
                                                }
                                                helperText={
                                                  props.touched.quantity &&
                                                  props.errors.quantity
                                                    ? `${props.errors.quantity}`
                                                    : ""
                                                }
                                                style={{
                                                  width: "250px",
                                                  float: "left",
                                                }}
                                              />
                                            </Col>
                                          </Form.Group>
                                          <Row>
                                            <Col lg={3} className="mb-3">
                                              <Button
                                                style={{
                                                  width: "150px",
                                                  height: "35px",
                                                  float: "left",
                                                }}
                                                variant="outline-primary"
                                                className="ml-2"
                                                type="submit"
                                              >
                                                <h3
                                                  style={{
                                                    float: "left",
                                                    marginTop: "-6px",
                                                    marginRight: "5px",
                                                  }}
                                                >
                                                  +
                                                </h3>
                                                <h6
                                                  style={{
                                                    float: "left",
                                                    marginTop: "1px",
                                                  }}
                                                >
                                                  Add To List
                                                </h6>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Form>

                                        <div>
                                          <div style={{ overflow: "scroll" }}>
                                            <table className="summary-table ">
                                              <thead className="bg-info">
                                                <tr>
                                                  <th>Id</th>
                                                  <th>Name</th>
                                                  <th>Color</th>
                                                  <th>Unit</th>
                                                  <th>Type</th>
                                                  <th>Qty</th>
                                                  <th
                                                    style={{
                                                      minWidth: "100px",
                                                    }}
                                                  >
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {rawMaterialValue.map(
                                                  (request, index) => (
                                                    <tr key={index}>
                                                      <td>{index + 1}</td>
                                                      <td>
                                                        {rawMaterials.map(
                                                          (rawMaterial) =>
                                                            rawMaterial.Id ===
                                                            parseInt(
                                                              request.rawMaterialId
                                                            )
                                                              ? rawMaterial.name
                                                              : ""
                                                        )}
                                                      </td>
                                                      <td>
                                                        {rawMaterials.map(
                                                          (material) =>
                                                            material.Id ===
                                                            parseInt(
                                                              request.rawMaterialId
                                                            )
                                                              ? colors.map(
                                                                  (color) =>
                                                                    color.Id ===
                                                                    parseInt(
                                                                      material.colorId
                                                                    )
                                                                      ? color.colorName
                                                                      : ""
                                                                )
                                                              : ""
                                                        )}
                                                      </td>
                                                      <td>
                                                        {rawMaterials.map(
                                                          (material) =>
                                                            material.Id ===
                                                            parseInt(
                                                              request.rawMaterialId
                                                            )
                                                              ? units.map(
                                                                  (unit) =>
                                                                    unit.Id ===
                                                                    parseInt(
                                                                      material.unitId
                                                                    )
                                                                      ? unit.unitName
                                                                      : ""
                                                                )
                                                              : ""
                                                        )}
                                                      </td>
                                                      <td>
                                                        {rawMaterials.map(
                                                          (material) =>
                                                            material.Id ===
                                                            parseInt(
                                                              request.rawMaterialId
                                                            )
                                                              ? types.map(
                                                                  (type) =>
                                                                    type.Id ===
                                                                    parseInt(
                                                                      material.typeId
                                                                    )
                                                                      ? type.typeName
                                                                      : ""
                                                                )
                                                              : ""
                                                        )}
                                                      </td>
                                                      <td>
                                                        {request.quantity}
                                                      </td>
                                                      <td>
                                                        <Button
                                                          variant="outline-danger"
                                                          onClick={(e) => {
                                                            const newItem =
                                                              rawMaterialValue.find(
                                                                (i) =>
                                                                  parseInt(
                                                                    i.rawMaterialId
                                                                  ) ===
                                                                  parseInt(
                                                                    request.rawMaterialId
                                                                  )
                                                              );

                                                            const newrawMaterialValue =
                                                              rawMaterialValue?.filter(
                                                                (i) =>
                                                                  parseInt(
                                                                    i.rawMaterialId
                                                                  ) !==
                                                                  parseInt(
                                                                    request.rawMaterialId
                                                                  )
                                                              );
                                                            setRawMaterialValue(
                                                              newrawMaterialValue
                                                            );
                                                          }}
                                                        >
                                                          <div
                                                            className="row"
                                                            style={{
                                                              marginBottom:
                                                                "-10px",
                                                              marginLeft:
                                                                "-10px",
                                                            }}
                                                          >
                                                            <div className="col">
                                                              <FontAwesomeIcon
                                                                style={{
                                                                  float:
                                                                    "right",
                                                                  marginRight:
                                                                    "-5px",
                                                                }}
                                                                icon={
                                                                  faTrashCan
                                                                }
                                                              />
                                                            </div>
                                                            <div className="col-8">
                                                              <h6
                                                                style={{
                                                                  float: "left",
                                                                  marginLeft:
                                                                    "-3px",
                                                                }}
                                                              >
                                                                Delete
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </Button>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Formik>

                                  <Row className="mt-3">
                                    <Col>
                                      <Button
                                        style={{
                                          width: "150px",

                                          height: "40px",
                                        }}
                                        variant="outline-primary"
                                        onClick={clearData}
                                      >
                                        <div className="row mt-1">
                                          <div className="col">
                                            <FontAwesomeIcon
                                              style={{ float: "right" }}
                                              icon={faBackwardStep}
                                            />
                                          </div>
                                          <div className="col-9">
                                            <h6 style={{ float: "left" }}>
                                              Back To List
                                            </h6>
                                          </div>
                                        </div>
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button
                                        style={{
                                          width: "150px",
                                          height: "40px",
                                        }}
                                        variant="outline-primary"
                                        type="submit"
                                        onClick={(e) => {
                                          handleSubmits();
                                        }}
                                      >
                                        <div className="row mt-1">
                                          <div className="col">
                                            <FontAwesomeIcon
                                              style={{ float: "right" }}
                                              icon={faFloppyDisk}
                                            />
                                          </div>
                                          <div className="col-7">
                                            <h6 style={{ float: "left" }}>
                                              Submit
                                            </h6>
                                          </div>
                                        </div>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}{" "}
            {(roleData.roleId === 5 || roleData.roleId === 2) &&
              parseInt(id) === 2 && (
                <div className="card card-info card-outline">
                  <div className="card-header">
                    {pop && (
                      <Button
                        onClick={(e) => {
                          setPop(false);
                        }}
                        variant="outline-success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Back
                            </h6>
                          </div>
                        </div>
                      </Button>
                    )}
                  </div>
                  <div className="card-body">
                    <div>
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                          height: "40px",
                        }}
                      >
                        <span style={{ float: "left" }}>
                          Raw Order / Home / List
                        </span>
                      </div>
                      <hr />
                    </div>
                    {!pop ? (
                      <DataGrid
                        rows={rowsRaw}
                        columns={columnsraw}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        initialState={{
                          ...rowsRaw.initialState,
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                        }}
                        onCellClick={(row) => handleOrder(row.row.Id)}
                        pageSizeOptions={[5, 10, 15]}
                        //  getRowId={(row) => row.Id}
                      />
                    ) : (
                      <Box>
                        <div className="row">
                          {rawOrders.map((rawOrder) => (
                            <div className="col-md-6 col-lg-4 mb-2 ">
                              <div
                                className="row p-3"
                                style={{
                                  width: "290px",
                                  height: "250px",
                                  borderRadius: "10px",
                                  background: "rgb(235, 235, 235)",
                                }}
                              >
                                <div className="mt-3 col-6">
                                  <img
                                    src={`http://garment.server.highendtradingplc.com/${
                                      products.find(
                                        (p) => p.Id === rawOrder.productId
                                      ).photo
                                    }`}
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                    }}
                                    alt="Photo"
                                  />
                                </div>
                                <div className="mt-2 mb-3  col-6" style={{}}>
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Scale-1:</b>{" "}
                                    <span>{rawOrder.scale1}</span>
                                  </div>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Scale-2:</b>{" "}
                                    <span>{rawOrder.scale2}</span>
                                  </div>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Scale-3:</b>{" "}
                                    <span>{rawOrder.scale3}</span>
                                  </div>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Scale-4:</b>{" "}
                                    <span>{rawOrder.scale4}</span>
                                  </div>
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      (p.typeId === 2 || p.typeId === 1)
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        <b>Scale-5:</b>{" "}
                                        <span>{rawOrder.scale5}</span>
                                      </div>
                                    </>
                                  )}
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 2
                                  ) && (
                                    <>
                                      <br />
                                      <div style={{ float: "left" }}>
                                        <b>Scale-6:</b>{" "}
                                        <span>{rawOrder.scale6}</span>
                                      </div>
                                    </>
                                  )}
                                  <br />
                                  <div style={{ float: "left" }}>
                                    {" "}
                                    <b>Quantity:</b>{" "}
                                    <span>{rawOrder.quantity}</span>
                                  </div>
                                </div>
                                <div style={{ float: "none" }} className="">
                                  {!!products.find(
                                    (p) =>
                                      p.Id === rawOrder.productId &&
                                      p.typeId === 3
                                  ) && (
                                    <>
                                      <br />
                                    </>
                                  )}
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "-20px",
                                    }}
                                    className=""
                                  >
                                    <b className="">Name:</b>
                                    <span className="">
                                      {
                                        products.find(
                                          (p) => p.Id === rawOrder.productId
                                        )?.name
                                      }
                                    </span>
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "-20px",
                                    }}
                                    className=""
                                  >
                                    <b className="">Type:</b>{" "}
                                    <span className="">
                                      {
                                        types.find(
                                          (t) =>
                                            t.Id ===
                                            products.find(
                                              (p) => p.Id === rawOrder.productId
                                            )?.typeId
                                        )?.typeName
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <hr />
                        <Row>
                          <DataGrid
                            rows={rowsOrder}
                            columns={columnsOrder}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            components={{
                              Toolbar: GridToolbar,
                            }}
                            initialState={{
                              ...rowsOrder.initialState,
                              pagination: {
                                paginationModel: { pageSize: 5 },
                              },
                            }}
                            //  onCellClick={(raw) => handleOrder(raw.id)}
                            pageSizeOptions={[5, 10, 15]}
                            // getRowId={(row) => row.Id}
                          />
                        </Row>

                        <div c>
                          <center>
                            <Button
                              style={{ width: "120px" }}
                              onClick={async (e) => {
                                const material = rawMaterials.find((r) => {
                                  let result = true;
                                  order.map((o) => {
                                    if (
                                      o.rawMaterialId === r.Id &&
                                      o.quantity > r.quantity
                                    ) {
                                      result = false;
                                    }
                                  });
                                  return result;
                                });
                                if (!!!material) {
                                  alert("There is no available quantity !");
                                  return;
                                }

                                const raw = orderRefs.find(
                                  (r) =>
                                    r.Id ===
                                    order.find((rr) => rr.Id > 0)?.rawOrderRefId
                                );
                                const pId = productions.find(
                                  (p) =>
                                    p.productionOrderRefId ===
                                    raw.productionOrderRefId
                                );
                                await axios.put(
                                  "http://garment.server.highendtradingplc.com/rawOrderRef/update/" +
                                    raw.Id,
                                  { statusId: 2 }
                                );
                                await axios.put(
                                  "http://garment.server.highendtradingplc.com/production/update/" +
                                    pId.Id,
                                  {
                                    materialReceiveDate: new Date(),
                                  }
                                );
                                for (let i = 0; i < order.length; i++) {
                                  const rawMaterial = rawMaterials.find(
                                    (r) => r.Id === order[i].rawMaterialId
                                  );
                                  rawMaterial.quantity =
                                    parseInt(rawMaterial.quantity) -
                                    parseInt(order[i].quantity);
                                  console.log(rawMaterial);
                                  await axios.put(
                                    "http://garment.server.highendtradingplc.com/rawMaterial/update/" +
                                      rawMaterial.Id,
                                    rawMaterial
                                  );
                                }
                                fetchAllData();
                                setPop(false);
                              }}
                              variant="outline-success"
                              className="col-5 mt-2 mb-2 ml-4 mr-4"
                            >
                              Approve
                            </Button>
                            <Button
                              style={{ width: "120px" }}
                              onClick={async (e) => {
                                const raw = orderRefs.find(
                                  (r) =>
                                    r.Id ===
                                    order.find((rr) => rr.Id > 0)?.rawOrderRefId
                                );
                                const pId = productions.find(
                                  (p) =>
                                    p.productionOrderRefId ===
                                    raw.productionOrderRefId
                                );
                                await axios.put(
                                  "http://garment.server.highendtradingplc.com/rawOrderRef/update/" +
                                    raw.Id,
                                  { statusId: 3 }
                                );
                                fetchAllData();
                                setPop(false);
                              }}
                              variant="outline-danger"
                              className="col-5 mt-2 mb-2 ml-4 mr-4"
                            >
                              Reject
                            </Button>
                          </center>
                        </div>
                      </Box>
                    )}
                  </div>
                </div>
              )}
          </div>
        </center>
      </main>
    </div>
  );
};

export default RawOrder;
