/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
const RawPurchaseReport = () => {
  const [purchases, setPurchases] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [allPurchase, setAllPurchase] = useState([]);
  const [purcahseDate, setPurcahseDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [purchase, vendor] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/rawPurchaseRef"),
        axios.get("http://garment.server.highendtradingplc.com/vendor"),
      ]);
      setAllPurchase(purchase.data);
      setVendors(vendor.data);

      let data = [];
      purchase.data?.map((qua) => {
        const date = new Date(qua.date);

        if (
          (date.getUTCFullYear() > purcahseDate.start.getUTCFullYear() &&
            date.getUTCFullYear() < purcahseDate.end.getUTCFullYear()) ||
          (date.getUTCFullYear() >= purcahseDate.start.getUTCFullYear() &&
            date.getUTCMonth() > purcahseDate.start.getUTCMonth() &&
            date.getUTCFullYear() <= purcahseDate.end.getUTCFullYear() &&
            date.getUTCMonth() < purcahseDate.end.getUTCMonth()) ||
          (date.getUTCFullYear() >= purcahseDate.start.getUTCFullYear() &&
            date.getUTCMonth() >= purcahseDate.start.getUTCMonth() &&
            date.getUTCDate() >= purcahseDate.start.getUTCDate() &&
            date.getUTCFullYear() <= purcahseDate.end.getUTCFullYear() &&
            date.getUTCMonth() <= purcahseDate.end.getUTCMonth() &&
            date.getUTCDate() <= purcahseDate.end.getUTCDate())
        ) {
          data.push(qua);
        }
        setPurchases(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "PurchaseID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fullName",
      headerName: "Vendor Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];

  const rows = purchases?.map((purchase, index) => {
    const vendor = vendors.find((s) => s.Id === purchase.vendorId);

    return {
      Id: index + 1,
      id: purchase.Id,
      fullName: vendor.fullName,
      price: purchase.totalPrice,
      date: purchase.date,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2" to={`/sales_report`}>
                Sales
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Raw Purchase
              </Link>

              <Link className="p-2" to={`/raw_order_report`}>
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>
                    Home / Raw Purchase Report
                  </span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                <Form>
                  <Form.Group as={Row}>
                    <Col md={5} className="mb-2">
                      <Form.Label
                        style={{
                          margin: "7px 5px 0 0",
                        }}
                      >
                        From
                      </Form.Label>
                      <TextField
                        type="date"
                        size="small"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (
                            new Date() >= new Date(event.target.value) &&
                            new Date(purcahseDate.end) >=
                              new Date(event.target.value)
                          ) {
                            purcahseDate.start = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col md={5} className="mb-2 ">
                      <Form.Label
                        style={{
                          margin: "7px 27px 0 0",
                        }}
                      >
                        To
                      </Form.Label>
                      <TextField
                        size="small"
                        type="date"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (new Date() >= new Date(event.target.value)) {
                            purcahseDate.end = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <center>
                        <Button
                          onClick={(e) => {
                            let data = [];
                            allPurchase?.map((qua) => {
                              const date = new Date(qua.date);
                              if (
                                (date.getUTCFullYear() >
                                  purcahseDate.start.getUTCFullYear() &&
                                  date.getUTCFullYear() <
                                    purcahseDate.end.getUTCFullYear()) ||
                                (date.getUTCFullYear() >=
                                  purcahseDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >
                                    purcahseDate.start.getUTCMonth() &&
                                  date.getUTCFullYear() <=
                                    purcahseDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <
                                    purcahseDate.end.getUTCMonth()) ||
                                (date.getUTCFullYear() >=
                                  purcahseDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >=
                                    purcahseDate.start.getUTCMonth() &&
                                  date.getUTCDate() >=
                                    purcahseDate.start.getUTCDate() &&
                                  date.getUTCFullYear() <=
                                    purcahseDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <=
                                    purcahseDate.end.getUTCMonth() &&
                                  date.getUTCDate() <=
                                    purcahseDate.end.getUTCDate())
                              ) {
                                data.push(qua);
                              }
                            });
                            setPurchases(data);
                          }}
                          variant="outline-info"
                        >
                          Search
                        </Button>
                      </center>
                    </Col>
                  </Form.Group>
                </Form>
                <hr />
                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1de5ec",
                      color: "white",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pageSize={5}
                    getRowId={(row) => row.Id}
                  />
                </Box>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default RawPurchaseReport;
