/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faBackwardStep,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required.")
    .min(3, "Too Short!")
    .max(40, "Too Long"),
  file: Yup.mixed().required("File is Required!!!"),

  typeId: Yup.string().required("Tpe is required"),
});
const ProductsPage = () => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const fetchAllData = async () => {
    try {
      const [product, type] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
      ]);
      setProducts(product.data);
      setTypes(type.data.filter((t) => t.Id < 4));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      typeId: "",

      file: "",

      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        const { file } = formik.values;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", values.name);
        formData.append("typeId", values.typeId);

        if (values.id === "") {
          const { name } = values;
          const userExists = products.find((product) => product?.name === name);
          if (userExists) {
            formik.setErrors({
              name: "name already exists.",
            });
            return;
          }

          await axios.post(
            "http://garment.server.highendtradingplc.com/products/add",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          setOpen(false);
        } else {
          const product = products.find((cust) => cust.Id === values.id);
          if (values.file === product.photo) {
            console.log(values, product.photo);
            await axios.put(
              "http://garment.server.highendtradingplc.com/products/update/" +
                values.id,
              {
                typeId: values.typeId,
                name: values.name,
                photo: values.file,
              }
            );
          } else {
            await axios.put(
              "http://garment.server.highendtradingplc.com/products/updateFile/" +
                values.id,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          }

          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
    },
  });
  const handleAdd = () => {
    formik.values.typeId = "";
    formik.values.name = "";
    formik.values.file = "";
    formik.values.id = "";
    setOpen(true);
  };
  const handleUpdate = (id) => {
    const product = products.find((cust) => cust.Id === parseInt(id));
    formik.values.typeId = product.typeId;
    formik.values.name = product.name;

    formik.values.file = product.photo;
    formik.values.id = parseInt(id);
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "http://garment.server.highendtradingplc.com/products/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: " Name",
      minWidth: 150,
    },

    {
      field: "typeId",
      headerAlign: "center",
      align: "center",
      headerName: "Type",
      minWidth: 120,

      valueGetter: (params) =>
        types.find((type) => type.Id === params)?.typeName || "",
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      minWidth: 240,

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div className="">
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-5px",
                }}
              >
                <Link className="p-2" to={`/raw_material`}>
                  Raw Material
                </Link>
                <Link
                  className="p-2"
                  onClick={(e) => window.location.reload()}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Product
                </Link>
                <Link className="p-2" to={`/raw_purchase`}>
                  Raw Purchase
                </Link>
              </div>

              <div className="card card-info card-outline">
                <div className="card-header pl-2">
                  {!open && (
                    <Button
                      variant="outline-info"
                      style={{ width: "150px", height: "35px", float: "left" }}
                      onClick={handleAdd}
                    >
                      <h3
                        style={{
                          float: "left",
                          marginTop: "-6px",
                          marginRight: "5px",
                        }}
                      >
                        +
                      </h3>
                      <h6 style={{ float: "left", marginTop: "1px" }}>
                        {" "}
                        Create New
                      </h6>
                    </Button>
                  )}
                  {open && (
                    <div>
                      <h5 style={{ float: "left" }}>
                        {formik.values.id === "" ? "Create New" : "Update"}{" "}
                        Products
                      </h5>
                      <Button
                        onClick={handleOpen}
                        variant="outline-success"
                        className="m-1"
                        style={{ float: "right" }}
                      >
                        <div
                          className="row"
                          style={{
                            marginBottom: "-10px",
                            marginLeft: "-10px",
                          }}
                        >
                          <div className="col">
                            <FontAwesomeIcon
                              style={{ float: "right", marginRight: "-1px" }}
                              icon={faBackwardStep}
                            />
                          </div>
                          <div className="col-7">
                            <h6
                              style={{
                                float: "left",
                                marginLeft: "-10px",
                              }}
                            >
                              Back
                            </h6>
                          </div>
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {!open && (
                    <div>
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                          height: "40px",
                        }}
                      >
                        <span style={{ float: "left" }}>Home / Products</span>
                      </div>
                      <hr />
                    </div>
                  )}
                  {open && (
                    <div>
                      <div
                        className="nav-bra pl-3 ml-1 p-2"
                        style={{
                          backgroundColor: "rgb(235, 235, 235)",
                        }}
                      >
                        <span>
                          Home / Products /{" "}
                          {formik.values.id === "" ? "Create" : "Update"}{" "}
                        </span>
                      </div>
                      <hr />
                    </div>
                  )}
                  {!open && (
                    <DataGrid
                      rows={products}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...products.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                  )}
                  {open && (
                    <center>
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={formik.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                              error={formik.touched.name && formik.errors.name}
                              helperText={
                                formik.touched.name && formik.errors.name
                                  ? `${formik.errors.name}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <TextField
                              id="outlined-basic"
                              label="Select Type"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="typeId"
                              select
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.typeId}
                              error={
                                formik.touched.typeId && formik.errors.typeId
                              }
                              helperText={
                                formik.touched.typeId && formik.errors.typeId
                                  ? `${formik.errors.typeId}`
                                  : ""
                              }
                              style={{ width: "300px" }}
                            >
                              <MenuItem key={null} value={null}>
                                None
                              </MenuItem>
                              {console.log(types)}
                              {types.map((type) => (
                                <MenuItem key={type.Id} value={type.Id}>
                                  {type.typeName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Row style={{ width: "300px" }}>
                              <h5
                                className="col-3"
                                style={{
                                  marginTop: "5px",
                                  marginLeft: "-2px",
                                }}
                              >
                                <b>Photo</b>
                              </h5>{" "}
                              <TextField
                                className="col"
                                type="file"
                                name="file"
                                size="small"
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "file",
                                    e.currentTarget.files[0]
                                  )
                                }
                                style={{ width: "180px", marginLeft: "9px" }}
                              />
                            </Row>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "300px" }}
                              variant="outline-success"
                              type="submit"
                            >
                              {formik.values.id === "" ? "Create" : "Update"}
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default ProductsPage;
