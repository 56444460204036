/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";

const RawOrderReport = () => {
  const [rawOrders, setRawOrders] = useState([]);
  const [allRawOrder, setAllRawOrder] = useState([]);
  const [productions, setProductions] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setColors] = useState([]);
  const [rawMaterials, setRawMaterial] = useState([]);
  const [rawOrderRefs, setRawOrderRef] = useState([]);
  const [rawOrderDate, setRawOrderDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [
        production,
        productionOrder,
        product,
        type,
        unit,
        rawMaterial,
        rawOrder,
        rawOrderRef,
        color,
      ] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
        axios.get("http://garment.server.highendtradingplc.com/unit"),
        axios.get("http://garment.server.highendtradingplc.com/rawMaterial"),
        axios.get("http://garment.server.highendtradingplc.com/rawOrder"),
        axios.get("http://garment.server.highendtradingplc.com/rawOrderRef"),
        axios.get("http://garment.server.highendtradingplc.com/color"),
      ]);
      setAllRawOrder(rawOrder.data);
      setProductions(production.data);
      setProductionOrders(productionOrder.data);
      setProducts(product.data);
      setTypes(type.data);
      setUnits(unit.data);
      setRawMaterial(rawMaterial.data);
      setRawOrderRef(rawOrderRef.data);
      setColors(color.data);
      let data = [];

      rawOrderRef.data?.map((qua) => {
        const date = new Date(qua.reqdate);
        if (
          (date.getUTCFullYear() > rawOrderDate.start.getUTCFullYear() &&
            date.getUTCFullYear() < rawOrderDate.end.getUTCFullYear()) ||
          (date.getUTCFullYear() >= rawOrderDate.start.getUTCFullYear() &&
            date.getUTCMonth() > rawOrderDate.start.getUTCMonth() &&
            date.getUTCFullYear() <= rawOrderDate.end.getUTCFullYear() &&
            date.getUTCMonth() < rawOrderDate.end.getUTCMonth()) ||
          (date.getUTCFullYear() >= rawOrderDate.start.getUTCFullYear() &&
            date.getUTCMonth() >= rawOrderDate.start.getUTCMonth() &&
            date.getUTCDate() >= rawOrderDate.start.getUTCDate() &&
            date.getUTCFullYear() <= rawOrderDate.end.getUTCFullYear() &&
            date.getUTCMonth() <= rawOrderDate.end.getUTCMonth() &&
            date.getUTCDate() <= rawOrderDate.end.getUTCDate())
        ) {
          const pro = productionOrder.data.find(
            (o) => o.Id === qua.productionOrderId
          );
          const pros = production.data.find(
            (o) => o.productionOrderId === pro.Id
          );
          rawOrder.data.map((r) => {
            if (
              r.rawOrderRefId === qua.Id &&
              pro.userId === roleData.Id &&
              !!pro
            ) {
              data.push(r);
            }
          });
        }
        setRawOrders(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "#",
      minWidth: 80,
    },
    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: "Order Ref ID",
      minWidth: 120,
    },

    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Raw Material Name",
      minWidth: 150,
    },
    {
      field: "type",
      headerAlign: "center",
      align: "center",
      headerName: "Type",
      minWidth: 150,
    },
    {
      field: "color",
      headerAlign: "center",
      align: "center",
      headerName: "Color",
      minWidth: 120,
    },
    {
      field: "unit",
      headerAlign: "center",
      align: "center",
      headerName: "Unit",
      minWidth: 120,
    },
    {
      field: "date",
      headerAlign: "center",
      align: "center",
      headerName: "Receive Date",
      minWidth: 120,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const rows = rawOrders.map((qlt) => {
    const rawOrderRef = rawOrderRefs.find((r) => r.Id === qlt.rawOrderRefId);
    console.log(rawOrderRefs, qlt);
    // const productionOrder = productionOrders.find(
    //   (p) => p.Id === rawOrderRef.productionOrderId
    // );
    const rawMaterial = rawMaterials.find((r) => r.Id === qlt.rawMaterialId);
    // const product = products.find((p) => p.Id === productionOrder.productId);
    const type = types.find((t) => t.Id === rawMaterial.typeId);
    const unit = units.find((u) => u.Id === rawMaterial.unitId);
    const color = colors.find((c) => c.Id === rawMaterial.colorId);
    return {
      Id: qlt?.Id,
      id: rawOrderRef.Id,
      //    product: product?.name,
      type: type?.typeName,
      unit: unit?.unitName,
      color: color?.colorName,
      name: rawMaterial.name,
      date: rawOrderRef?.reqdate,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2" to={`/sales_report`}>
                Sales
              </Link>
              <Link className="p-2" to={`/raw_purchase_report`}>
                Raw Purchase
              </Link>

              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>Home / Raw Order Report</span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                <Form>
                  <Form.Group as={Row}>
                    <Col md={5} className="mb-2">
                      <Form.Label
                        style={{
                          margin: "7px 5px 0 0",
                        }}
                      >
                        From
                      </Form.Label>
                      <TextField
                        type="date"
                        size="small"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (
                            new Date() >= new Date(event.target.value) &&
                            new Date(rawOrderDate.end) >=
                              new Date(event.target.value)
                          ) {
                            rawOrderDate.start = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col md={5} className="mb-2 ">
                      <Form.Label
                        style={{
                          margin: "7px 27px 0 0",
                        }}
                      >
                        To
                      </Form.Label>
                      <TextField
                        size="small"
                        type="date"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (new Date() >= new Date(event.target.value)) {
                            rawOrderDate.end = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <center>
                        <Button
                          onClick={(e) => {
                            let data = [];
                            rawOrderRefs?.map((qua) => {
                              const date = new Date(qua.reqdate);
                              if (
                                (date.getUTCFullYear() >
                                  rawOrderDate.start.getUTCFullYear() &&
                                  date.getUTCFullYear() <
                                    rawOrderDate.end.getUTCFullYear()) ||
                                (date.getUTCFullYear() >=
                                  rawOrderDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >
                                    rawOrderDate.start.getUTCMonth() &&
                                  date.getUTCFullYear() <=
                                    rawOrderDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <
                                    rawOrderDate.end.getUTCMonth()) ||
                                (date.getUTCFullYear() >=
                                  rawOrderDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >=
                                    rawOrderDate.start.getUTCMonth() &&
                                  date.getUTCDate() >=
                                    rawOrderDate.start.getUTCDate() &&
                                  date.getUTCFullYear() <=
                                    rawOrderDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <=
                                    rawOrderDate.end.getUTCMonth() &&
                                  date.getUTCDate() <=
                                    rawOrderDate.end.getUTCDate())
                              ) {
                                allRawOrder.map((a) => {
                                  if (a.rawOrderRefId === qua.Id) {
                                    data.push(a);
                                  }
                                });
                              }
                            });
                            setRawOrders(data);
                          }}
                          variant="outline-info"
                        >
                          Search
                        </Button>
                      </center>
                    </Col>
                  </Form.Group>
                </Form>
                <hr />
                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1de5ec",
                      color: "white",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      ...productions.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.Id}
                  />
                </Box>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default RawOrderReport;
