/** @format */
import React, { useEffect, useState, useRef } from "react";
import Header from "../Header";
import { Button, Modal, Form, Col, Row, Table } from "react-bootstrap";
import { Autocomplete, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faList,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import axios from "axios";

function ProductionOrderPage() {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [branchs, setColors] = useState([]);
  const [productId, setProductId] = useState(null);
  const [productCheck, setProductCheck] = useState(null);
  const [customerCheck, setCustomerCheck] = useState(null);
  const [customerValue, setCustomerValue] = useState(null);
  const [paymentCheck, setPaymentCheck] = useState(null);
  const [paymentValue, setPaymentValue] = useState(null);
  const [branchCheck, setBranchCheck] = useState(null);
  const [branchValue, setBranchValue] = useState(null);
  const [productValue, setProductValue] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [valids, setValid] = useState([0, 0, 0, 0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [types, setTypes] = useState([]);
  const [update, setUpdate] = useState({
    productId: "",
    quantity: "",
    price: "",
    scale1: "",
    scale2: "",
    scale3: "",
    scale4: "",
    scale5: "",
    scale6: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const componentRef = useRef();
  const fetchAllData = async () => {
    try {
      const [customer, product, branch, paymentMethod, type] =
        await Promise.all([
          axios.get("http://garment.server.highendtradingplc.com/customer"),
          axios.get("http://garment.server.highendtradingplc.com/products"),
          axios.get("http://garment.server.highendtradingplc.com/branch"),
          axios.get(
            "http://garment.server.highendtradingplc.com/paymentMethod"
          ),
          axios.get("http://garment.server.highendtradingplc.com/type"),
        ]);
      setCustomers(customer.data);
      setProducts(product.data);
      setColors(branch.data);
      setPaymentMethods(paymentMethod.data);
      setTypes(type.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const productData = {
    options: products,
    getOptionLabel: (option) => option?.name,
  };
  const customerData = {
    options: customers,
    getOptionLabel: (option) => option?.fullName,
  };

  const paymentData = {
    options: paymentMethods,
    getOptionLabel: (option) => option?.name,
  };
  const branchData = {
    options: branchs,
    getOptionLabel: (option) => option?.location,
  };
  const validationSchema = Yup.object().shape({
    orderDeadlineDate: Yup.date().required("Date is required!"),
    orderReceiveDate: Yup.date().required("Date is required!"),
    transferidId: Yup.string().required("Transferid ID is required!"),
    price: Yup.number()
      .required("Price is required!")
      .min(0, "Too Low!")
      .max(1000000, "Too High!"),
  });
  const formik = useFormik({
    initialValues: {
      customerId: "",
      branchId: "",
      paymentMethodId: "",
      transferidId: "",
      orderReceiveDate: "",
      orderDeadlineDate: "",
      totalPrice: "",
      userId: roleData.Id,
      price: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        values.totalPrice = price.grandTotal;
        if (values.totalPrice - values.price < 0) {
          formik.setErrors(formik.errors.price);
          return;
        }
        values.userId = roleData.Id;
        console.log(values, productValue);
        await axios
          .post(
            "http://garment.server.highendtradingplc.com/productionOrderRef/add",
            values
          )
          .then(async (res) => {
            console.log(res);
            for (let i = 0; i <= productValue.length - 1; ) {
              await axios
                .post(
                  "http://garment.server.highendtradingplc.com/productionOrder/add",
                  {
                    productId: productValue[i].productId,
                    productionOrderRefId: res.data.data.Id,
                    price: productValue[i].price,
                    quantity: productValue[i].quantity,
                    scale1: productValue[i].scale1,
                    scale4: productValue[i].scale4,
                    scale2: productValue[i].scale2,
                    scale3: productValue[i].scale3,
                    scale5: productValue[i].scale5,
                    scale6: productValue[i].scale6,
                  }
                )
                .then((r) => {
                  i = i + 1;
                });
            }
            await axios.post(
              "http://garment.server.highendtradingplc.com/salesRef/add",
              {
                productionOrderRefId: res.data.data.Id,
                totalPrice: values.totalPrice,
                orderDeadlineDate: values.orderDeadlineDate,
                customerId: values.customerId,
                remainPrice: values.totalPrice - values.price,
                statusId: 1,
                branchId: values.branchId,
              }
            );
          });
        setOpenPrint(true);
      } catch (error) {
        console.log(error);
      }
    },
  });
  const clearData = () => {
    price.total = 0;
    price.vat = 0;
    price.grandTotal = 0;
    setProductValue([]);
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
    console.log(valids);
  };

  return (
    <div>
      <div>
        <Header />
      </div>

      <main style={{ marginTop: "190px" }}>
        {!openPrint && (
          <center>
            <div style={{ maxWidth: "1100px" }}>
              <div
                className="tab-container "
                style={{
                  marginTop: "-20px",
                  marginBottom: "-5px",
                }}
              >
                <Link
                  className="p-2"
                  onClick={(e) => {
                    window.location.reload();
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px 5px 0 0",
                    border: "solid 0.05em rgb(181, 181, 181)",
                  }}
                >
                  Production Order
                </Link>
                <Link className="p-2" to={`/raw_order/1`}>
                  Order List
                </Link>
              </div>
              <div className="card card-info card-outline">
                <div className="card-header">
                  <h4>New Production Order Entry</h4>
                </div>
                <div className="card-body">
                  <div>
                    <div
                      className="nav-bra pl-3 ml-1 p-2 "
                      style={{
                        backgroundColor: "rgb(235, 235, 235)",
                        height: "40px",
                      }}
                    >
                      <span style={{ float: "left" }}>
                        {" "}
                        Production Order / Create
                      </span>
                    </div>
                    <hr />
                    <div>
                      <div>
                        <Form className="">
                          <Row>
                            <Col lg={8}>
                              <Row className="mb-3">
                                <Col>
                                  <Row
                                    style={{ width: "300px", float: "left" }}
                                  >
                                    <div className="col">
                                      <Autocomplete
                                        {...customerData}
                                        id="control  led-demo"
                                        size="small"
                                        variant="outlined"
                                        name="customerId"
                                        className="mb-3 ml-2"
                                        onBlur={(e) => handleValidity(0)}
                                        value={customerValue}
                                        onChange={(event, newValue) => {
                                          if (!newValue?.Id) {
                                            setCustomerCheck(null);
                                            setCustomerValue(null);
                                            return;
                                          }
                                          setCustomerValue(newValue);
                                          formik.values.customerId =
                                            newValue?.Id;
                                          setCustomerCheck(5);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            style={{ width: "230px" }}
                                            error={
                                              valids[0] === 1 &&
                                              customerCheck === null
                                            }
                                            label={
                                              <div style={{ float: "left" }}>
                                                <FontAwesomeIcon
                                                  icon={faUser}
                                                  style={{
                                                    float: "left",
                                                    marginRight: "10px",
                                                  }}
                                                />
                                                <span>Customer</span>
                                              </div>
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="col">
                                      <Button
                                        variant="outline-info"
                                        //onClick={openAddModal}
                                        style={{
                                          width: "30px",
                                          height: "40px",

                                          float: "left",
                                        }}
                                        onClick={handleOpen}
                                      >
                                        <h2
                                          style={{
                                            marginTop: "-5px",
                                            marginLeft: "-7px",
                                          }}
                                        >
                                          +
                                        </h2>
                                      </Button>
                                    </div>
                                  </Row>
                                </Col>
                                <Col>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    disabled
                                    value={null}
                                    type="text"
                                    label={
                                      <div style={{ float: "left" }}>
                                        <FontAwesomeIcon
                                          icon={faPhone}
                                          size="1x"
                                          style={{
                                            float: "left",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <span>
                                          {!!customerValue?.phoneNumber
                                            ? customerValue?.phoneNumber
                                            : "Phone"}
                                        </span>
                                      </div>
                                    }
                                    size="small"
                                    className="mb-3 ml-1"
                                    style={{ width: "300px", float: "left" }}
                                  />
                                </Col>
                                <Col>
                                  <Autocomplete
                                    {...branchData}
                                    id="controlled-demo"
                                    size="small"
                                    variant="outlined"
                                    name="branchId"
                                    className="mb-3 ml-1"
                                    onBlur={(e) => handleValidity(1)}
                                    value={branchValue}
                                    onChange={(event, newValue) => {
                                      if (!newValue?.Id) {
                                        setBranchCheck(null);
                                        setBranchValue(null);
                                        return;
                                      }
                                      setBranchValue(newValue);
                                      formik.values.branchId = newValue?.Id;
                                      setBranchCheck(5);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        style={{
                                          width: "300px",
                                          float: "left",
                                        }}
                                        error={
                                          valids[1] === 1 &&
                                          branchCheck === null
                                        }
                                        label={
                                          <div style={{ float: "left" }}>
                                            <FontAwesomeIcon
                                              icon={faUser}
                                              style={{
                                                float: "left",
                                                marginRight: "10px",
                                              }}
                                            />
                                            <span>Branch</span>
                                          </div>
                                        }
                                      />
                                    )}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder="MM/DD/YYYY"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.orderReceiveDate}
                                    type="date"
                                    label={
                                      <div
                                        style={{
                                          float: "left",
                                          width: "170px",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCalendar}
                                          size="1x"
                                          style={{
                                            float: "left",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <h4>
                                          <b>Receive Date</b>
                                        </h4>
                                      </div>
                                    }
                                    size="small"
                                    name="orderReceiveDate"
                                    error={
                                      formik.touched.orderReceiveDate &&
                                      formik.errors.orderReceiveDate
                                    }
                                    helperText={
                                      formik.touched.orderReceiveDate &&
                                      formik.errors.orderReceiveDate
                                        ? `${formik.errors.orderReceiveDate}`
                                        : ""
                                    }
                                    className="mb-3 ml-1"
                                    style={{ width: "300px", float: "left" }}
                                  />
                                </Col>
                                <Col>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    className="mb-3 ml-1"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.orderDeadlineDate}
                                    type="date"
                                    label={
                                      <div
                                        style={{
                                          float: "left",
                                          width: "180px",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCalendar}
                                          size="1x"
                                          style={{
                                            float: "left",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <h4>
                                          <b>Deadline Date</b>
                                        </h4>
                                      </div>
                                    }
                                    size="small"
                                    name="orderDeadlineDate"
                                    error={
                                      formik.touched.orderDeadlineDate &&
                                      formik.errors.orderDeadlineDate
                                    }
                                    helperText={
                                      formik.touched.orderDeadlineDate &&
                                      formik.errors.orderDeadlineDate
                                        ? `${formik.errors.orderDeadlineDate}`
                                        : ""
                                    }
                                    style={{ width: "300px", float: "left" }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                      <Formik
                        initialValues={{
                          productId: "",
                          quantity: "",
                          price: "",
                          totalprice: "",
                          scale1: "",
                          scale4: "",
                          scale2: "",
                          scale3: "",
                          scale5: "",
                          scale6: "",
                        }}
                        validationSchema={Yup.object().shape({
                          quantity: Yup.string().required(
                            "Quantity is required"
                          ),
                          price: Yup.string().required("Price is required"),
                          scale1: Yup.number()
                            .required("Scale is required")
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                          scale4: Yup.number()
                            .required("Scale is required")
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                          scale2: Yup.number()
                            .required("Scale is required")
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                          scale3: Yup.number()
                            .required("Scale is required")
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                          scale5: Yup.number()
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                          scale6: Yup.number()
                            .min(0, "Too Low!")
                            .max(1000, "Too high!"),
                        })}
                        onSubmit={async (values) => {
                          try {
                            if (productCheck === null) {
                              return;
                            }
                            // const checkItem = productValue?.find(
                            //   (i) => i?.productId === productId.Id
                            // );
                            // if (!!checkItem) {
                            //   alert("You use the same product!");
                            //   setProductId(null);

                            //   return;
                            // }
                            update.price = values.price;
                            update.quantity = values.quantity;
                            values.totalprice = update.quantity * update.price;

                            // price.vat =
                            //   parseFloat(price.vat) +
                            //   parseFloat(update.price) * parseFloat(update.quantity);
                            price.total =
                              parseFloat(price.total) +
                              parseFloat(values.totalprice);
                            price.grandTotal = price.total + price.vat;
                            setProductId(null);

                            setProductValue([
                              ...productValue,
                              {
                                productId: values.productId,
                                quantity: update.quantity,
                                price: update.price,

                                totalprice: values.totalprice,
                                scale1:
                                  values.scale1 === "" ? "0" : values.scale1,
                                scale4:
                                  values.scale4 === "" ? "0" : values.scale4,
                                scale2:
                                  values.scale2 === "" ? "0" : values.scale2,
                                scale3:
                                  values.scale3 === "" ? "0" : values.scale3,
                                scale5:
                                  values.scale5 === "" ? "0" : values.scale5,
                                scale6:
                                  values.scale6 === "" ? "0" : values.scale6,
                              },
                            ]);
                          } catch (error) {
                            console.log(error);
                          } finally {
                            values.price = "";
                            values.quantity = "";
                            values.scale1 = "";
                            values.scale2 = "";
                            values.scale3 = "";
                            values.scale4 = "";
                            values.scale5 = "";
                            values.scale6 = "";
                          }
                        }}
                      >
                        {(props) => (
                          <div className="">
                            <hr />

                            <div
                              className="row "
                              style={{
                                marginBottom: "-10px",
                                marginLeft: "-10px",
                              }}
                            >
                              <div className="col-1">
                                <FontAwesomeIcon
                                  style={{ float: "right" }}
                                  icon={faList}
                                />
                              </div>
                              <div className="col-6">
                                <h6
                                  style={{
                                    float: "left",
                                    marginLeft: "-10px",
                                  }}
                                >
                                  Production Order Product List
                                </h6>
                              </div>
                            </div>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                              <Form.Group
                                as={Row}
                                controlId="formHorizontalUsername"
                              >
                                <Col md={3} className="mb-3">
                                  <Autocomplete
                                    {...productData}
                                    id="controlled-demo"
                                    size="small"
                                    name="productId"
                                    value={productId}
                                    onBlur={(e) => handleValidity(3)}
                                    onChange={(event, newValue) => {
                                      if (!newValue?.Id) {
                                        setProductCheck(null);
                                        setProductId(null);
                                        return;
                                      }
                                      setProductId(newValue);
                                      props.values.productId = newValue?.Id;
                                      setProductCheck(5);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={
                                          <div style={{ float: "left" }}>
                                            <span>Select Products</span>
                                          </div>
                                        }
                                        error={
                                          valids[3] === 1 &&
                                          productCheck === null
                                        }
                                        style={{
                                          width: "300px",
                                          float: "left",
                                        }}
                                      />
                                    )}
                                  />
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                <Col lg={4} className="mb-1">
                                  <Row>
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      label="Qty"
                                      variant="outlined"
                                      type="text"
                                      size="small"
                                      name="quantity"
                                      onChange={props.handleChange}
                                      value={props.values.quantity}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                      }
                                      helperText={
                                        props.touched.quantity &&
                                        props.errors.quantity
                                          ? `${props.errors.quantity}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>

                                  <Row>
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      variant="outlined"
                                      label="Price"
                                      type="text"
                                      size="small"
                                      name="price"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.price}
                                      error={
                                        props.touched.price &&
                                        props.errors.price
                                      }
                                      helperText={
                                        props.touched.price &&
                                        props.errors.price
                                          ? `${props.errors.price}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>
                                </Col>

                                <Col lg={4} className="mb-1">
                                  <Row>
                                    {" "}
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      label="Scale 1"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale1"
                                      onChange={props.handleChange}
                                      value={props.values.scale1}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale1 &&
                                        props.errors.scale1
                                      }
                                      helperText={
                                        props.touched.scale1 &&
                                        props.errors.scale1
                                          ? `${props.errors.scale1}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>

                                  <Row>
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      label="Scale 2"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale2"
                                      onChange={props.handleChange}
                                      value={props.values.scale2}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale2 &&
                                        props.errors.scale2
                                      }
                                      helperText={
                                        props.touched.scale2 &&
                                        props.errors.scale2
                                          ? `${props.errors.scale2}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>
                                </Col>

                                <Col lg={4} className="mb-1">
                                  <Row>
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      label="Scale 3"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale3"
                                      onChange={props.handleChange}
                                      value={props.values.scale3}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale3 &&
                                        props.errors.scale3
                                      }
                                      helperText={
                                        props.touched.scale3 &&
                                        props.errors.scale3
                                          ? `${props.errors.scale3}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>

                                  <Row>
                                    <TextField
                                      className="mb-2 ml-2"
                                      id="outlined-basic"
                                      label="Scale 4"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale4"
                                      onChange={props.handleChange}
                                      value={props.values.scale4}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale4 &&
                                        props.errors.scale4
                                      }
                                      helperText={
                                        props.touched.scale4 &&
                                        props.errors.scale4
                                          ? `${props.errors.scale4}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Row>
                                </Col>
                              </Form.Group>
                              <Form.Group as={Row}>
                                {(productId?.typeId === 1 ||
                                  productId?.typeId === 2) && (
                                  <Col lg={4} className="mb-1">
                                    <TextField
                                      className="mb-2 "
                                      id="outlined-basic"
                                      label="Scale 5"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale5"
                                      onChange={props.handleChange}
                                      value={props.values.scale5}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale5 &&
                                        props.errors.scale5
                                      }
                                      helperText={
                                        props.touched.scale5 &&
                                        props.errors.scale5
                                          ? `${props.errors.scale5}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Col>
                                )}
                                {productId?.typeId === 2 && (
                                  <Col lg={4}>
                                    <TextField
                                      className="mb-2 "
                                      id="outlined-basic"
                                      label="Scale 6"
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      name="scale6"
                                      onChange={props.handleChange}
                                      value={props.values.scale6}
                                      onBlur={props.handleBlur}
                                      error={
                                        props.touched.scale6 &&
                                        props.errors.scale6
                                      }
                                      helperText={
                                        props.touched.scale6 &&
                                        props.errors.scale6
                                          ? `${props.errors.scale6}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Col>
                                )}
                                <Col md={3} className="mb-3">
                                  <Button
                                    style={{ width: "150px", height: "40px" }}
                                    variant="outline-primary"
                                    type="submit"
                                  >
                                    <h3
                                      style={{
                                        float: "left",
                                        marginTop: "-6px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      +
                                    </h3>
                                    <h6
                                      style={{
                                        float: "left",
                                        marginTop: "1px",
                                      }}
                                    >
                                      Add To List
                                    </h6>
                                  </Button>
                                </Col>
                              </Form.Group>
                            </Form>

                            <div>
                              <div className="" style={{ overflow: "scroll" }}>
                                <table className="summary-table ">
                                  <thead className="bg-info">
                                    <tr>
                                      <th>No</th>
                                      <th>Product</th>
                                      <th>Scale1</th>
                                      <th>Scale2</th>
                                      <th>Scale3</th>
                                      <th>Scale4</th>
                                      <th>Scale5</th>
                                      <th>Scale6</th>
                                      <th>Qty</th>
                                      <th>U.Price</th>
                                      <th>T.Price</th>
                                      <th
                                        style={{
                                          width: "300px",
                                          float: "left",
                                        }}
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {productValue.map((request, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {products.map((product) =>
                                            product.Id ===
                                            parseInt(request.productId)
                                              ? product.name
                                              : ""
                                          )}
                                        </td>

                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale1 === ""
                                                  ? request.scale1
                                                  : update.scale1
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale1: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale1: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale1
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale2 === ""
                                                  ? request.scale2
                                                  : update.scale2
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale2: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale2: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale2
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale3 === ""
                                                  ? request.scale3
                                                  : update.scale3
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale3: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale3: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale3
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale4 === ""
                                                  ? request.scale4
                                                  : update.scale4
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale4: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale4: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale4
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale5 === ""
                                                  ? request.scale5
                                                  : update.scale5
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale5: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale5: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale5
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.scale6 === ""
                                                  ? request.scale6
                                                  : update.scale6
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    0 ||
                                                  parseFloat(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale6: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    scale6: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.scale6
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              minLength={1}
                                              maxLength={20}
                                              className="form-control"
                                              required
                                              style={{
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              value={
                                                update.quantity === ""
                                                  ? request.quantity
                                                  : update.quantity
                                              }
                                              onChange={(e) => {
                                                if (
                                                  parseInt(e.target.value) <
                                                    1 ||
                                                  parseInt(e.target.value) >
                                                    10000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    quantity: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    quantity: parseInt(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }
                                              }}
                                            />
                                          ) : (
                                            request.quantity
                                          )}
                                        </td>
                                        <td>
                                          {openUpdate &&
                                          index === update.productId ? (
                                            <input
                                              type="number"
                                              className={
                                                parseFloat(update.price) < 50
                                                  ? "form-control is-invalid"
                                                  : "form-control"
                                              }
                                              minLength={100}
                                              maxLength={1000000}
                                              required
                                              value={
                                                update.price === ""
                                                  ? request.price
                                                  : update.price
                                              }
                                              style={{
                                                margin: "0 ",
                                                maxWidth: "150px",
                                                minWidth: "100px",
                                              }}
                                              onChange={(e) => {
                                                if (
                                                  parseFloat(e.target.value) <
                                                    1 ||
                                                  parseFloat(e.target.value) >
                                                    1000000
                                                ) {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    price: 1,
                                                  }));
                                                } else {
                                                  setUpdate((prev) => ({
                                                    ...prev,
                                                    price: parseFloat(
                                                      e.target.value
                                                    ),
                                                  }));
                                                }

                                                setProductValue([
                                                  ...productValue,
                                                ]);
                                              }}
                                            />
                                          ) : (
                                            request.price
                                          )}
                                        </td>
                                        <td>{request.totalprice}</td>
                                        <td>
                                          {(index !== update.productId ||
                                            !openUpdate) && (
                                            <div>
                                              <Button
                                                variant="outline-warning"
                                                className="mr-3"
                                                onClick={(e) => {
                                                  setOpenUpdate(true);
                                                  console.log("hhhhhh");
                                                  setUpdate({
                                                    productId: index,
                                                    price: "",
                                                    quantity: "",
                                                    scale1: "",
                                                    scale2: "",
                                                    scale3: "",
                                                    scale4: "",
                                                    scale5: "",
                                                    scale6: "",
                                                  });
                                                }}
                                              >
                                                <div
                                                  className="row "
                                                  style={{
                                                    marginBottom: "-10px",
                                                    marginLeft: "-10px",
                                                  }}
                                                >
                                                  <div className="col">
                                                    <FontAwesomeIcon
                                                      style={{ float: "right" }}
                                                      icon={faPenToSquare}
                                                    />
                                                  </div>
                                                  <div className="col-6">
                                                    <h6
                                                      style={{
                                                        float: "left",
                                                        marginLeft: "-10px",
                                                      }}
                                                    >
                                                      Edit
                                                    </h6>
                                                  </div>
                                                </div>
                                              </Button>
                                              <Button
                                                variant="outline-danger"
                                                onClick={(e) => {
                                                  const newProduct =
                                                    productValue.find(
                                                      (i, index) =>
                                                        index === request.index
                                                    );
                                                  const newproductValue =
                                                    productValue?.filter(
                                                      (i) =>
                                                        parseInt(
                                                          i.productId
                                                        ) !==
                                                        parseInt(
                                                          request.productId
                                                        )
                                                    );
                                                  setProductValue(
                                                    newproductValue
                                                  );
                                                  price.total =
                                                    parseFloat(price.total) -
                                                    parseFloat(
                                                      newProduct.price
                                                    ) *
                                                      newProduct.quantity;
                                                  price.grandTotal =
                                                    price.total;
                                                }}
                                              >
                                                <div
                                                  className="row"
                                                  style={{
                                                    marginBottom: "-10px",
                                                    marginLeft: "-10px",
                                                  }}
                                                >
                                                  <div className="col">
                                                    <FontAwesomeIcon
                                                      style={{
                                                        float: "right",
                                                        marginRight: "-5px",
                                                      }}
                                                      icon={faTrashCan}
                                                    />
                                                  </div>
                                                  <div className="col-8">
                                                    <h6
                                                      style={{
                                                        float: "left",
                                                        marginLeft: "-3px",
                                                      }}
                                                    >
                                                      Delete
                                                    </h6>
                                                  </div>
                                                </div>
                                              </Button>
                                            </div>
                                          )}

                                          {openUpdate &&
                                            index === update.productId && (
                                              <div>
                                                <Button
                                                  variant="outline-success"
                                                  className="mr-3"
                                                  onClick={(e) => {
                                                    if (
                                                      parseFloat(update.price) <
                                                        50 ||
                                                      parseFloat(update.price) >
                                                        1000000
                                                    ) {
                                                      return;
                                                    }
                                                    const type = products.find(
                                                      (p) =>
                                                        p.Id ===
                                                        productValue[index]
                                                          .productId
                                                    );
                                                    price.grandTotal = 0;
                                                    price.total = 0;
                                                    price.vat = 0;
                                                    productValue[index].price =
                                                      !!update.price
                                                        ? parseFloat(
                                                            update.price
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .price
                                                          );
                                                    productValue[
                                                      index
                                                    ].quantity =
                                                      !!update.quantity
                                                        ? parseFloat(
                                                            update.quantity
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .quantity
                                                          );
                                                    productValue[index].scale1 =
                                                      !!update.scale1
                                                        ? parseFloat(
                                                            update.scale1
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale1
                                                          );
                                                    productValue[index].scale2 =
                                                      !!update.scale2
                                                        ? parseFloat(
                                                            update.scale2
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale2
                                                          );
                                                    productValue[index].scale3 =
                                                      !!update.scale3
                                                        ? parseFloat(
                                                            update.scale3
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale3
                                                          );
                                                    productValue[index].scale4 =
                                                      !!update.scale4
                                                        ? parseFloat(
                                                            update.scale4
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale4
                                                          );
                                                    if (
                                                      type.typeId === 1 ||
                                                      type.typeId === 2
                                                    ) {
                                                      productValue[
                                                        index
                                                      ].scale5 = !!update.scale5
                                                        ? parseFloat(
                                                            update.scale5
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale5
                                                          );
                                                    }
                                                    if (type.typeId === 2) {
                                                      productValue[
                                                        index
                                                      ].scale6 = !!update.scale6
                                                        ? parseFloat(
                                                            update.scale6
                                                          )
                                                        : parseFloat(
                                                            productValue[index]
                                                              .scale6
                                                          );
                                                    }

                                                    productValue[
                                                      index
                                                    ].totalprice =
                                                      productValue[index]
                                                        .price *
                                                      productValue[index]
                                                        .quantity;
                                                    setProductValue([
                                                      ...productValue,
                                                    ]);
                                                    update.productId = "";
                                                    update.price = "";
                                                    update.quantity = "";
                                                    productValue.map((i) => {
                                                      price.total =
                                                        parseFloat(
                                                          price.total
                                                        ) +
                                                        parseFloat(i.price) *
                                                          i.quantity;
                                                    });
                                                    price.grandTotal =
                                                      price.total + price.vat;
                                                  }}
                                                >
                                                  <div
                                                    className="row "
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-20px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                        }}
                                                        className="fa-regular"
                                                        icon={faFloppyDisk}
                                                      />
                                                    </div>
                                                    <div className="col-6">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-10px",
                                                        }}
                                                      >
                                                        Save
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                                <Button
                                                  variant="outline-danger"
                                                  onClick={(e) => {
                                                    setOpenUpdate(false);
                                                    setUpdate({
                                                      productId: "",
                                                      price: "",
                                                      quantity: "",
                                                    });
                                                  }}
                                                >
                                                  <div
                                                    className="row"
                                                    style={{
                                                      marginBottom: "-10px",
                                                      marginLeft: "-20px",
                                                    }}
                                                  >
                                                    <div className="col">
                                                      <FontAwesomeIcon
                                                        style={{
                                                          float: "right",
                                                          marginRight: "-5px",
                                                        }}
                                                        icon={faXmark}
                                                      />
                                                    </div>
                                                    <div className="col-8">
                                                      <h6
                                                        style={{
                                                          float: "left",
                                                          marginLeft: "-3px",
                                                        }}
                                                      >
                                                        Cancel
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </Button>
                                              </div>
                                            )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div
                                className=" mt-2"
                                style={{
                                  textAlign: "right",
                                  marginBottom: "20px",
                                  float: "right",
                                }}
                              >
                                <Form.Group
                                  as={Row}
                                  controlId="formHorizontalUsername"
                                  style={{ width: "300px" }}
                                >
                                  <Col>
                                    <Form.Label>Total : </Form.Label>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      id="outlined-basic"
                                      disabled
                                      label="Qty"
                                      variant="outlined"
                                      type="text"
                                      size="small"
                                      name="qty"
                                      value={price.total}
                                      style={{ width: "150px" }}
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  controlId="formHorizontalUsername"
                                  style={{ width: "300px" }}
                                >
                                  <Col>
                                    <Form.Label>VAT : </Form.Label>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      id="outlined-basic"
                                      disabled
                                      label="Qty"
                                      variant="outlined"
                                      type="text"
                                      size="small"
                                      name="qty"
                                      value={price.vat}
                                      style={{ width: "150px" }}
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  as={Row}
                                  controlId="formHorizontalUsername"
                                  style={{ width: "300px" }}
                                >
                                  <Col>
                                    <Form.Label>Grand Total : </Form.Label>
                                  </Col>
                                  <Col>
                                    <Form.Control
                                      id="outlined-basic"
                                      disabled
                                      label="Qty"
                                      variant="outlined"
                                      type="text"
                                      size="small"
                                      name="qty"
                                      value={price.grandTotal}
                                      style={{ width: "150px" }}
                                    />
                                  </Col>
                                </Form.Group>
                              </div>
                              <div>
                                <Row>
                                  <Col lg={4} className="mb-2">
                                    <Autocomplete
                                      {...paymentData}
                                      id="controlled-demo"
                                      size="small"
                                      variant="outlined"
                                      name="paymentMethodId"
                                      onBlur={(e) => handleValidity(2)}
                                      value={paymentValue}
                                      onChange={(event, newValue) => {
                                        if (!newValue?.Id) {
                                          setPaymentCheck(null);
                                          setPaymentValue(null);
                                          return;
                                        }
                                        setPaymentValue(newValue);
                                        formik.values.paymentMethodId =
                                          newValue?.Id;
                                        setPaymentCheck(5);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{
                                            width: "300px",
                                            float: "left",
                                          }}
                                          error={
                                            valids[2] === 1 &&
                                            paymentCheck === null
                                          }
                                          label={
                                            <div style={{ float: "left" }}>
                                              <FontAwesomeIcon
                                                icon={faUser}
                                                style={{
                                                  float: "left",
                                                  marginRight: "10px",
                                                }}
                                              />
                                              <span>Payment Method</span>
                                            </div>
                                          }
                                        />
                                      )}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-2">
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      label="First Payment "
                                      type="number"
                                      size="small"
                                      name="price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.price}
                                      error={
                                        formik.touched.price &&
                                        formik.errors.price
                                      }
                                      helperText={
                                        formik.touched.price &&
                                        formik.errors.price
                                          ? `${formik.errors.price}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Col>
                                  <Col lg={4} className="mb-2">
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      label="Tranferid ID"
                                      type="text"
                                      size="small"
                                      name="transferidId"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.transferidId}
                                      error={
                                        formik.touched.transferidId &&
                                        formik.errors.transferidId
                                      }
                                      helperText={
                                        formik.touched.transferidId &&
                                        formik.errors.transferidId
                                          ? `${formik.errors.transferidId}`
                                          : ""
                                      }
                                      style={{ width: "300px", float: "left" }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        )}
                      </Formik>
                    </div>
                    <Row>
                      <Col>
                        <Button
                          style={{
                            width: "150px",

                            height: "40px",
                          }}
                          variant="outline-primary"
                          onClick={clearData}
                        >
                          <div className="row mt-1">
                            <div className="col">
                              <FontAwesomeIcon
                                style={{ float: "right" }}
                                icon={faBackwardStep}
                              />
                            </div>
                            <div className="col-9">
                              <h6 style={{ float: "left" }}>Back To List</h6>
                            </div>
                          </div>
                        </Button>
                      </Col>
                      <Col>
                        <Form onSubmit={formik.handleSubmit}>
                          <Button
                            style={{
                              width: "150px",

                              height: "40px",
                            }}
                            variant="outline-primary"
                            type="submit"
                          >
                            <div className="row mt-1">
                              <div className="col">
                                <FontAwesomeIcon
                                  style={{ float: "right" }}
                                  icon={faFloppyDisk}
                                />
                              </div>
                              <div className="col-7">
                                <h6 style={{ float: "left" }}>Submit</h6>
                              </div>
                            </div>
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <Formik
                initialValues={{
                  fullName: "",
                  tinNumber: "",
                  address: "",
                  phoneNumber: "",
                }}
                validationSchema={Yup.object().shape({
                  fullName: Yup.string()
                    .required("Full name is required.")
                    .min(8, "Too Short!")
                    .max(60, "Too Long"),
                  phoneNumber: Yup.string()
                    .matches(
                      /^\d{9,10}$/,
                      "Phone number must be 9 or 10 digits."
                    )
                    .required("Phone number is required."),
                  address: Yup.string().required("Address is required"),
                })}
                onSubmit={async (values) => {
                  try {
                    if (
                      !(
                        values.tinNumber.length === 10 ||
                        values.tinNumber.length === 0
                      )
                    ) {
                      formik.setErrors({
                        tinNumber:
                          "Till number must be empty or exactly 10 digits.",
                      });
                      return;
                    }
                    await axios.post(
                      "http://garment.server.highendtradingplc.com/customer/add",
                      values
                    );
                  } catch (error) {
                    console.log(error);
                  } finally {
                    fetchAllData();
                    setOpen(false);
                  }
                }}
              >
                {(props) => (
                  <Modal
                    show={open}
                    onHide={handleOpen}
                    style={{ minWidth: "700px" }}
                  >
                    <Modal.Header closeButton>
                      <span>Productio Order / Customer / Create</span>
                    </Modal.Header>
                    <Modal.Body>
                      <Form
                        className="m-2 ml-5 pl-5"
                        onSubmit={props.handleSubmit}
                      >
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={8}>
                            <TextField
                              id="outlined-basic"
                              label="Full Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="fullName"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.fullName}
                              isInvalid={
                                props.touched.fullName && props.errors.fullName
                              }
                              style={{ maxWidth: "300px" }}
                            />

                            {props.touched.fullName &&
                              props.errors.fullName && (
                                <div className="text-danger mt-1">
                                  {props.errors.fullName}
                                </div>
                              )}
                          </Col>
                        </Form.Group>

                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={8}>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="phoneNumber"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.phoneNumber}
                              isInvalid={
                                props.touched.phoneNumber &&
                                props.errors.phoneNumber
                              }
                              style={{ maxWidth: "300px" }}
                            />

                            {props.touched.phoneNumber &&
                              props.errors.phoneNumber && (
                                <div className="text-danger mt-1">
                                  {props.errors.phoneNumber}
                                </div>
                              )}
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={8}>
                            <TextField
                              id="outlined-basic"
                              label="Address"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="address"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.address}
                              isInvalid={
                                props.touched.address && props.errors.address
                              }
                              style={{ maxWidth: "300px" }}
                            />

                            {props.touched.address && props.errors.address && (
                              <div className="text-danger mt-1">
                                {props.errors.address}
                              </div>
                            )}
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalUsername"
                        >
                          <Col sm={12}>
                            <Button
                              style={{ width: "62%" }}
                              variant="outline-success"
                              type="submit"
                            >
                              Create
                            </Button>
                          </Col>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                  </Modal>
                )}
              </Formik>
            </div>
          </center>
        )}
        {openPrint && (
          <center>
            <div
              className="card card-info card-outline"
              style={{ width: "1100px", overflow: "scroll" }}
            >
              <div className="card-body m-1">
                <Container ref={componentRef}>
                  <div className="row mt-2">
                    <div className="col-sm-6 ">
                      <br />
                      <h1 style={{ marginLeft: "5vw", marginBottom: "2vh" }}>
                        Invoice
                      </h1>
                      <i
                        style={{ marginLeft: "7vw", marginBottom: "2vh" }}
                        className="fas fa-home fa-3x"
                      />
                    </div>

                    <div className="col-sm-6 ">
                      <br />
                      <div
                        style={{
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        <p style={{ margin: "0", fontSize: 24 }}>
                          <b> Company Name</b>
                        </p>
                        <p style={{ margin: "0" }}>{branchValue.phoneNumber}</p>
                        <p style={{ margin: "0" }}>email@gmail.com</p>
                        <p style={{ margin: "0" }}>{branchValue.location}</p>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <Row className="">
                    <Col sm={2}>
                      <p>
                        <b>BILL To</b>
                      </p>
                    </Col>

                    <Col sm={5}>
                      <div>
                        <p>
                          <b>Full Name : </b>
                          {customerValue?.fullName}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Phone No : </b>
                          {customerValue?.phoneNumber}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Payment Method : </b>
                          {paymentValue?.name}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>First Payment : </b>
                          {formik.values.price}
                        </p>
                      </div>
                    </Col>
                    <Col sm={5}>
                      <div>
                        <p>
                          <b>RefId : </b>
                          {`${
                            customerValue.Id
                          }${new Date().getUTCDay()}${new Date().getUTCFullYear()}${new Date().getUTCMonth()}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Tranferid ID : </b>
                          {`${formik.values.transferidId}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Receive Date : </b>
                          {`${new Date(
                            formik.values.orderReceiveDate
                          ).getUTCDate()}/${
                            new Date(
                              formik.values.orderReceiveDate
                            ).getUTCMonth() + 1
                          }/${new Date(
                            formik.values.orderReceiveDate
                          ).getUTCFullYear()}`}
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Deadline Date : </b>
                          {`${new Date(
                            formik.values.orderDeadlineDate
                          ).getUTCDate()}/${
                            new Date(
                              formik.values.orderDeadlineDate
                            ).getUTCMonth() + 1
                          }/${new Date(
                            formik.values.orderDeadlineDate
                          ).getUTCFullYear()}`}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div style={{ border: "solid 2px black" }}></div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <Row>
                    <Table className="text-center" style={{ width: "100%" }}>
                      <tr>
                        <th>No</th>
                        <th>Product</th>
                        <th>Type</th>
                        <th>Scale 1</th>
                        <th>Scale 2</th>
                        <th>Scale 3</th>
                        <th>Scale 4</th>
                        <th>Scale 5</th>
                        <th>Scale 6</th>
                        <th>Qty</th>
                        <th>U.Price</th>
                        <th>T.Price</th>
                      </tr>

                      <tbody>
                        {productValue.map((request, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>
                              {products.map((product) =>
                                product.Id === parseInt(request.productId)
                                  ? product.name
                                  : ""
                              )}
                            </td>
                            <td>
                              {products.map((product) =>
                                product.Id === parseInt(request.productId)
                                  ? types.map((type) =>
                                      type.Id === parseInt(product.typeId)
                                        ? type.typeName
                                        : ""
                                    )
                                  : ""
                              )}
                            </td>
                            <td>{request.scale1}</td>
                            <td>{request.scale2}</td>
                            <td>{request.scale3}</td>
                            <td>{request.scale4}</td>
                            <td>{request.scale5}</td>
                            <td>{request.scale6}</td>
                            <td>{request.quantity}</td>
                            <td>{request.price}</td>
                            <td>{request.totalprice}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <h3>Terms</h3>
                    </Col>
                    <Col sm={4}>
                      <Table>
                        <tbody>
                          <tr style={{}}>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                Total Price
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                {price.total}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                VAT
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                {price.vat}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  float: "right",
                                  fontSize: 18,
                                  width: "100px",
                                  height: "10px",
                                }}
                              >
                                Grand Total
                              </b>
                            </td>
                            <td>
                              <p
                                style={{
                                  float: "left",
                                  fontSize: 18,
                                  width: "110px",
                                  height: "10px",
                                }}
                              >
                                {price.grandTotal}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="card-footer row">
                <div className="col">
                  <center>
                    <ReactToPrint
                      trigger={() => (
                        <Button variant="outline-primary">Print</Button>
                      )}
                      content={() => componentRef.current}
                      onAfterPrint={() => {
                        window.location.reload();
                      }}
                    />
                  </center>
                </div>
                <div className="col">
                  <center>
                    <Button
                      variant="outline-success"
                      onClick={(e) => {
                        window.location.reload();
                      }}
                    >
                      Cancel
                    </Button>
                  </center>
                </div>
              </div>
            </div>
          </center>
        )}
      </main>
    </div>
  );
}

export default ProductionOrderPage;
