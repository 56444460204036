/** @format */
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { Checkbox, MenuItem } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faBox,
  faShoppingCart,
  faReceipt,
  faChartPie,
  faBaby,
} from "@fortawesome/free-solid-svg-icons";
import PeopleIcon from "@mui/icons-material/People";
import Header from "../Header";

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required.")
    .min(8, "Too Short!")
    .max(60, "Too Long"),
  phoneNumber: Yup.string()
    .matches(/^\d{9,10}$/, "Phone number must be 9 or 10 digits.")
    .required("Phone number is required."),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
});

const validationSchema2 = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  colorId: Yup.string().required("Color is required"),
  unitId: Yup.string().required("Unit is required"),
  typeId: Yup.string().required("Type is required"),
});
const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [opensup, setOpensup] = useState(false);
  const [openitem, setOpenitem] = useState(false);
  const [count, setCount] = useState(0);
  const [countsup, setCountsup] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [types, setTypes] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [units, setUnits] = useState([]);
  const [colors, setModels] = useState([]);
  const [items, setItems] = useState([]);
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchCount = async () => {
    try {
      const res = await axios.get(
        "http://garment.server.highendtradingplc.com/customer/count"
      );
      setCount(res.data.count);
      const res1 = await axios.get(
        "http://garment.server.highendtradingplc.com/customer"
      );
      setCustomers(res1.data);
      const ress = await axios.get(
        "http://garment.server.highendtradingplc.com/vendor/count"
      );
      setCountsup(ress.data.count);
      const ress1 = await axios.get(
        "http://garment.server.highendtradingplc.com/vendor"
      );
      setSuppliers(ress1.data);
      const res2 = await axios.get(
        "http://garment.server.highendtradingplc.com/unit"
      );
      setUnits(res2.data);
      const res3 = await axios.get(
        "http://garment.server.highendtradingplc.com/type"
      );
      setTypes(res3.data);
      const res4 = await axios.get(
        "http://garment.server.highendtradingplc.com/color"
      );
      setModels(res4.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);
  const formikitem = useFormik({
    initialValues: {
      name: "",
      unitId: "",
      colorId: "",
      typeId: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        const { name, code } = values;
        const itemexist = items.find(
          (item) => item?.name === name && item?.code === code
        );
        if (itemexist) {
          alert("Item already exists");
          handleCloseitem();
          resetForm();
          formikitem.setErrors("already exists");
          return;
        }
        await axios.post(
          "http://garment.server.highendtradingplc.com/item/add",
          values
        );
        fetchCount();
        handleCloseitem();
        resetForm();
        alert("Item added successfully");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      phoneNumber: "",
      address: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { fullName, tinNumber, phoneNumber } = values;
        if (
          !(values.tinNumber.length === 10 || values.tinNumber.length === 0)
        ) {
          formik.setErrors({
            tinNumber: "Till number must be empty or exactly 10 digits.",
          });
          return;
        }
        const Custexist = customers.find(
          (customer) =>
            customer?.fullName === fullName && customer?.tinNumber === tinNumber
        );
        const Custexist1 = customers.find(
          (customer) => customer?.phoneNumber === phoneNumber
        );
        if (Custexist) {
          alert("Customer already exists.");
          setOpen(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        if (Custexist1) {
          alert("Phone number already exists.");
          setOpen(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        await axios.post(
          "http://garment.server.highendtradingplc.com/customer/add",
          values
        );
        fetchCount();
        setOpen(false);
        resetForm();

        alert("Customer added successfully!");
      } catch (error) {
        console.log(error);
        alert("Failed to add customer.");
      }
    },
  });
  const formiksup = useFormik({
    initialValues: {
      fullName: "",

      phoneNumber: "",
      address: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { fullName, phoneNumber } = values;

        const Supexist = suppliers.find(
          (supplier) => supplier?.fullName === fullName
        );
        const Supexist1 = suppliers.find(
          (supplier) => supplier?.phoneNumber === phoneNumber
        );
        if (Supexist) {
          alert("Supplier already exists.");
          setOpensup(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        if (Supexist1) {
          alert("Phone number already exists.");
          setOpensup(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        await axios.post(
          "http://garment.server.highendtradingplc.com/vendor/add",
          values
        );
        setOpensup(false);
        resetForm();
        fetchCount();
        alert("Supplier added successfully!");
      } catch (error) {
        console.log(error);
        alert("Failed to add vendor.");
      }
    },
  });

  const handleAdd = () => {
    if (roleData.roleId === 2 || roleData.roleId === 3) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddsup = () => {
    if (roleData.roleId === 2 || roleData.roleId === 5) setOpensup(true);
  };

  const handleClosesup = () => {
    setOpensup(false);
  };
  const handleAdditem = () => {
    if (roleData.roleId === 2 || roleData.roleId === 5) setOpenitem(true);
  };
  const handleCloseitem = () => {
    setOpenitem(false);
  };

  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="card card-info card-outline text-center"
              style={{ backgroundColor: "white" }}
            >
              <h3 className="p-3">Welcome to Garment System</h3>
            </div>
            <div className="ml-5 pl-5">
              {" "}
              <div className="row ml-5 mr-3">
                {(roleData.roleId === 2 || roleData.roleId === 3) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Customer
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total:{count}
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            onClick={handleAdd}
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 5) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faUsers} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Vendor
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total: {countsup}
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            onClick={handleAddsup}
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 5) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faBox} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Raw Material
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total: {items.length}
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            onClick={handleAdditem}
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 5) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faBox} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Products
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "85px",
                              height: "30px",
                            }}
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 3) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faReceipt} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Production Order
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Request:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            href="/production_order"
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 3) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faShoppingCart} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Sales
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            href="/sales"
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 6) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faReceipt} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Raw Order
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Request:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "85px",
                              height: "30px",
                            }}
                            href="/raw_order"
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {(roleData.roleId === 2 || roleData.roleId === 5) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faReceipt} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Raw Purchase
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            href="/raw_purchase"
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {!(roleData.roleId === 1 || roleData.roleId === 4) && (
                  <div
                    className="col col-lg-3 ml-1"
                    style={{ minWidth: "270px", maxWidth: "350px" }}
                  >
                    <div className="info-box card-info card-outline row">
                      <span className="info-box-icon bg-info col-sm-3">
                        <FontAwesomeIcon icon={faChartPie} />
                      </span>
                      <div className="col">
                        <span
                          className="info-box-text"
                          style={{ float: "left" }}
                        >
                          Report
                        </span>
                        <br />
                        <span
                          className="info-box-number"
                          style={{ float: "left" }}
                        >
                          Total Balance:- 0
                        </span>
                        <br />
                        <span>
                          <Button
                            style={{
                              float: "right",
                              width: "90px",
                              height: "30px",
                            }}
                            href={
                              roleData.roleId === 2 || roleData.roleId === 3
                                ? `/production_order_report`
                                : roleData.roleId === 4
                                ? `/quality_checker_report`
                                : roleData.roleId === 5
                                ? `/raw_purchase_report`
                                : roleData.roleId === 6
                                ? `/raw_order_report`
                                : `/home`
                            }
                          >
                            <h3 style={{ float: "left", marginTop: "-8px" }}>
                              +
                            </h3>
                            <h6 style={{ float: "left" }}>New</h6>
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
      <Modal show={open} onHide={handleClose} style={{ minWidth: "700px" }}>
        <Modal.Header closeButton>
          <Modal.Title>Create Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formik.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  error={formik.touched.fullName && formik.errors.fullName}
                  helperText={
                    formik.touched.fullName && formik.errors.fullName
                      ? `${formik.errors.fullName}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Till Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="tinNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tinNumber}
                  error={formik.touched.tinNumber && formik.errors.tinNumber}
                  helperText={
                    formik.touched.tinNumber && formik.errors.tinNumber
                      ? `${formik.errors.tinNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? `${formik.errors.phoneNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={formik.touched.address && formik.errors.address}
                  helperText={
                    formik.touched.address && formik.errors.address
                      ? `${formik.errors.address}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Customer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={opensup}
        onHide={handleClosesup}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formiksup.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fullName"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.fullName}
                  error={
                    formiksup.touched.fullName && formiksup.errors.fullName
                  }
                  helperText={
                    formiksup.touched.fullName && formiksup.errors.fullName
                      ? `${formiksup.errors.fullName}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneNumber"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.phoneNumber}
                  error={
                    formiksup.touched.phoneNumber &&
                    formiksup.errors.phoneNumber
                  }
                  helperText={
                    formiksup.touched.phoneNumber &&
                    formiksup.errors.phoneNumber
                      ? `${formiksup.errors.phoneNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.address}
                  error={formiksup.touched.address && formiksup.errors.address}
                  helperText={
                    formiksup.touched.address && formiksup.errors.address
                      ? `${formiksup.errors.address}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Supplier
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openitem}
        onHide={handleCloseitem}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Raw Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formikitem.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.name}
                  error={formikitem.touched.name && formikitem.errors.name}
                  helperText={
                    formikitem.touched.name && formikitem.errors.name
                      ? `${formikitem.errors.name}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select Color"
                  variant="outlined"
                  select
                  size="small"
                  name="colorId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.colorId}
                  error={
                    formikitem.touched.colorId && formikitem.errors.colorId
                  }
                  helperText={
                    formikitem.touched.colorId && formikitem.errors.colorId
                      ? `${formikitem.errors.colorId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {colors.map((color) => {
                    return (
                      <MenuItem key={color.Id} value={color.Id}>
                        {color.colorName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select unit"
                  variant="outlined"
                  select
                  size="small"
                  name="unitId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.unitId}
                  error={formikitem.touched.unitId && formikitem.errors.unitId}
                  helperText={
                    formikitem.touched.unitId && formikitem.errors.unitId
                      ? `${formikitem.errors.unitId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {units.map((unit) => {
                    return (
                      <MenuItem key={unit.Id} value={unit.Id}>
                        {unit.unitName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select type"
                  variant="outlined"
                  select
                  size="small"
                  name="typeId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.typeId}
                  error={formikitem.touched.typeId && formikitem.errors.typeId}
                  helperText={
                    formikitem.touched.typeId && formikitem.errors.typeId
                      ? `${formikitem.errors.typeId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {types.map((type) => {
                    return (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.typeName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>

            <Button variant="outline-primary" type="submit">
              Add Raw Material
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomePage;
