/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";

const QualityCheckerReport = () => {
  const [qualityCheckers, setQualityCheckers] = useState([]);
  const [allQualityChecker, setAllQualityChecker] = useState([]);
  const [productions, setProductions] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState([]);
  const [qualityCheckerDate, setQualityCheckerDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [
        production,
        productionOrder,
        product,
        type,
        user,
        statu,
        qualityChecker,
      ] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrderRef"
        ),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
        axios.get("http://garment.server.highendtradingplc.com/user"),
        axios.get("http://garment.server.highendtradingplc.com/customer"),
        axios.get("http://garment.server.highendtradingplc.com/qualityChecker"),
      ]);
      setAllQualityChecker(qualityChecker.data);
      setProductions(production.data);
      setProductionOrders(productionOrder.data);
      setProducts(product.data);
      setTypes(type.data);
      setUsers(user.data);
      setStatus(statu.data);
      let data = [];

      qualityChecker.data?.map((qua) => {
        const date = new Date(qua.receiveDate);
        if (
          (date.getUTCFullYear() > qualityCheckerDate.start.getUTCFullYear() &&
            date.getUTCFullYear() < qualityCheckerDate.end.getUTCFullYear()) ||
          (date.getUTCFullYear() >= qualityCheckerDate.start.getUTCFullYear() &&
            date.getUTCMonth() > qualityCheckerDate.start.getUTCMonth() &&
            date.getUTCFullYear() <= qualityCheckerDate.end.getUTCFullYear() &&
            date.getUTCMonth() < qualityCheckerDate.end.getUTCMonth()) ||
          (date.getUTCFullYear() >= qualityCheckerDate.start.getUTCFullYear() &&
            date.getUTCMonth() >= qualityCheckerDate.start.getUTCMonth() &&
            date.getUTCDate() >= qualityCheckerDate.start.getUTCDate() &&
            date.getUTCFullYear() <= qualityCheckerDate.end.getUTCFullYear() &&
            date.getUTCMonth() <= qualityCheckerDate.end.getUTCMonth() &&
            date.getUTCDate() <= qualityCheckerDate.end.getUTCDate())
        ) {
          data.push(qua);
        }
        setQualityCheckers(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "name",
      headerAlign: "center",
      align: "center",
      headerName: "Full Name",
      minWidth: 150,
    },

    {
      field: "user",
      headerAlign: "center",
      align: "center",
      headerName: "User",
      minWidth: 120,
    },

    {
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      minWidth: 120,
    },
    {
      field: "date",
      headerAlign: "center",
      align: "center",
      headerName: "Receive Date",
      minWidth: 120,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const rows = qualityCheckers.map((qlt) => {
    const production = productions.find((p) => p.Id === qlt.productionId);
    const productionOrder = productionOrders.find(
      (p) => p.Id === production.productionOrderRefId
    );
    const customer = status.find((c) => c.Id === productionOrder.customerId);
    const user = users.find((u) => u.Id === qlt.userId);
    return {
      Id: qlt?.Id,
      name: customer?.fullName,
      user: user?.fullName,
      status: qlt.statusId === 1 ? "Approved" : "Rejected",
      date: qlt.receiveDate,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link className="p-2" to={`/sales_report`}>
                Sales
              </Link>
              <Link className="p-2" to={`/raw_purchase_report`}>
                Raw Purchase
              </Link>

              <Link className="p-2" to={`/raw_order_report`}>
                Raw Order
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}>
                    Home / Quality Checker Report
                  </span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                <Form>
                  <Form.Group as={Row}>
                    <Col md={5} className="mb-2">
                      <Form.Label
                        style={{
                          margin: "7px 5px 0 0",
                        }}
                      >
                        From
                      </Form.Label>
                      <TextField
                        type="date"
                        size="small"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (
                            new Date() >= new Date(event.target.value) &&
                            new Date(qualityCheckerDate.end) >=
                              new Date(event.target.value)
                          ) {
                            qualityCheckerDate.start = new Date(
                              event.target.value
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={5} className="mb-2 ">
                      <Form.Label
                        style={{
                          margin: "7px 27px 0 0",
                        }}
                      >
                        To
                      </Form.Label>
                      <TextField
                        size="small"
                        type="date"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (new Date() >= new Date(event.target.value)) {
                            qualityCheckerDate.end = new Date(
                              event.target.value
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <center>
                        <Button
                          onClick={(e) => {
                            let data = [];
                            allQualityChecker?.map((qua) => {
                              const date = new Date(qua.receiveDate);
                              if (
                                (date.getUTCFullYear() >
                                  qualityCheckerDate.start.getUTCFullYear() &&
                                  date.getUTCFullYear() <
                                    qualityCheckerDate.end.getUTCFullYear()) ||
                                (date.getUTCFullYear() >=
                                  qualityCheckerDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >
                                    qualityCheckerDate.start.getUTCMonth() &&
                                  date.getUTCFullYear() <=
                                    qualityCheckerDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <
                                    qualityCheckerDate.end.getUTCMonth()) ||
                                (date.getUTCFullYear() >=
                                  qualityCheckerDate.start.getUTCFullYear() &&
                                  date.getUTCMonth() >=
                                    qualityCheckerDate.start.getUTCMonth() &&
                                  date.getUTCDate() >=
                                    qualityCheckerDate.start.getUTCDate() &&
                                  date.getUTCFullYear() <=
                                    qualityCheckerDate.end.getUTCFullYear() &&
                                  date.getUTCMonth() <=
                                    qualityCheckerDate.end.getUTCMonth() &&
                                  date.getUTCDate() <=
                                    qualityCheckerDate.end.getUTCDate())
                              ) {
                                data.push(qua);
                              }
                            });
                            setQualityCheckers(data);
                          }}
                          variant="outline-info"
                        >
                          Search
                        </Button>
                      </center>
                    </Col>
                  </Form.Group>
                </Form>
                <hr />
                <Box
                  sx={{
                    height: 300,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1de5ec",
                      color: "white",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      ...productions.initialState,
                      pagination: {
                        paginationModel: { pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15]}
                    getRowId={(row) => row.Id}
                  />
                </Box>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default QualityCheckerReport;
