/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Autocomplete, Box, TextField } from "@mui/material";
const SalesReport = () => {
  const [sales, setSales] = useState([]);
  const [customers, setCustomer] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [oneCustomer, setOneCustomer] = useState(null);
  const [users, setUsers] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [salesDate, setSalesDate] = useState({
    start: new Date(new Date().setUTCDate(new Date().getUTCDate() - 1)),
    end: new Date(),
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    const [sales, customer, user, branch] = await Promise.all([
      axios.get("http://garment.server.highendtradingplc.com/salesRef"),
      axios.get("http://garment.server.highendtradingplc.com/customer"),
      axios.get("http://garment.server.highendtradingplc.com/user"),
      axios.get("http://garment.server.highendtradingplc.com/branch"),
    ]);
    setAllSales(sales.data);
    setCustomer(customer.data);
    setUsers(user.data);
    setBranchs(branch.data);
    let data = [];
    sales.data?.map((qua) => {
      const date = new Date(qua.receiveDate);
      if (
        (date.getUTCFullYear() > salesDate.start.getUTCFullYear() &&
          date.getUTCFullYear() < salesDate.end.getUTCFullYear()) ||
        (date.getUTCFullYear() >= salesDate.start.getUTCFullYear() &&
          date.getUTCMonth() > salesDate.start.getUTCMonth() &&
          date.getUTCFullYear() <= salesDate.end.getUTCFullYear() &&
          date.getUTCMonth() < salesDate.end.getUTCMonth()) ||
        (date.getUTCFullYear() >= salesDate.start.getUTCFullYear() &&
          date.getUTCMonth() >= salesDate.start.getUTCMonth() &&
          date.getUTCDate() >= salesDate.start.getUTCDate() &&
          date.getUTCFullYear() <= salesDate.end.getUTCFullYear() &&
          date.getUTCMonth() <= salesDate.end.getUTCMonth() &&
          date.getUTCDate() <= salesDate.end.getUTCDate())
      ) {
        data.push(qua);
      }
      setSales(data);
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const defaultProps = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };

  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 100,
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "SalesID",
      width: 100,
    },
    {
      field: "fullName",
      headerClassName: "super-app-theme--header",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "tinNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Tin",
      width: 150,
    },
    {
      field: "user",
      headerClassName: "super-app-theme--header",
      headerName: "User",
      width: 150,
    },
    {
      field: "branch",
      headerClassName: "super-app-theme--header",
      headerName: "Branch",
      width: 150,
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 150,
    },
    {
      field: "date",
      headerName: "Receive Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "orderDeadlineDate",
      headerName: "Deadline Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const rows = sales?.map((sale, index) => {
    const customer = customers.find((s) => s.Id === sale.customerId);
    const user = users.find((u) => u.Id === sale.userId);
    const branch = branchs.find((b) => b.Id === sale.branchId);
    return {
      Id: index + 1,
      id: sale?.Id,
      fullName: customer?.fullName,
      tinNumber: customer?.tinNumber,
      branch: branch?.location,
      price: sale?.totalPrice,
      date: sale?.receiveDate,
      user: user?.fullName,
      orderDeadlineDate: sale?.orderDeadlineDate,
    };
  });
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div
              className="tab-container "
              style={{
                marginTop: "-20px",
                marginBottom: "-5px",
              }}
            >
              <Link className="p-2" to={`/production_order_report`}>
                Productions Order
              </Link>
              <Link
                className="p-2"
                onClick={(e) => window.location.reload()}
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px 5px 0 0",
                  border: "solid 0.05em rgb(181, 181, 181)",
                }}
              >
                Sales
              </Link>
              <Link className="p-2" to={`/raw_purchase_report`}>
                Raw Purchase
              </Link>

              <Link className="p-2" to={`/raw_order_report`}>
                Raw Order
              </Link>
              <Link className="p-2" to={`/quality_checker_report`}>
                Quality Check
              </Link>
            </div>
            <div className="card card-info card-outline">
              {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px ",
                  }}
                >
                  <span style={{ float: "left" }}>Home / Sales Report</span>
                </div>
                <br />
                <div style={{ border: "solid 1px #ffb71b" }}></div>
                <br />
                <Form>
                  <Form.Group as={Row}>
                    <Col lg={3} className="mb-2">
                      <Autocomplete
                        {...defaultProps}
                        id="controlled-demo"
                        size="small"
                        value={oneCustomer}
                        onChange={(event, newValue) => {
                          setOneCustomer(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Customer" />
                        )}
                      />
                    </Col>
                    <Col lg={4} className="mb-2">
                      <Form.Label
                        style={{
                          margin: "7px 5px 0 0",
                        }}
                      >
                        From
                      </Form.Label>
                      <TextField
                        type="date"
                        size="small"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (
                            new Date() >= new Date(event.target.value) &&
                            new Date(salesDate.end) >=
                              new Date(event.target.value)
                          ) {
                            salesDate.start = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4} className="mb-2 ">
                      <Form.Label
                        style={{
                          margin: "7px 27px 0 0",
                        }}
                      >
                        To
                      </Form.Label>
                      <TextField
                        size="small"
                        type="date"
                        style={{ width: "200px" }}
                        onChange={(event) => {
                          if (new Date() >= new Date(event.target.value)) {
                            salesDate.end = new Date(event.target.value);
                          }
                        }}
                      />
                    </Col>
                    <Col lg={1}>
                      <center>
                        <Button
                          onClick={(e) => {
                            let data = [];
                            if (oneCustomer === null) {
                              allSales?.map((qua) => {
                                const date = new Date(qua.receiveDate);
                                if (
                                  (date.getUTCFullYear() >
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCFullYear() <
                                      salesDate.end.getUTCFullYear()) ||
                                  (date.getUTCFullYear() >=
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCMonth() >
                                      salesDate.start.getUTCMonth() &&
                                    date.getUTCFullYear() <=
                                      salesDate.end.getUTCFullYear() &&
                                    date.getUTCMonth() <
                                      salesDate.end.getUTCMonth()) ||
                                  (date.getUTCFullYear() >=
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCMonth() >=
                                      salesDate.start.getUTCMonth() &&
                                    date.getUTCDate() >=
                                      salesDate.start.getUTCDate() &&
                                    date.getUTCFullYear() <=
                                      salesDate.end.getUTCFullYear() &&
                                    date.getUTCMonth() <=
                                      salesDate.end.getUTCMonth() &&
                                    date.getUTCDate() <=
                                      salesDate.end.getUTCDate())
                                ) {
                                  data.push(qua);
                                }
                              });
                            } else {
                              allSales?.map((qua) => {
                                const date = new Date(qua.receiveDate);
                                if (
                                  (date.getUTCFullYear() >
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCFullYear() <
                                      salesDate.end.getUTCFullYear()) ||
                                  (date.getUTCFullYear() >=
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCMonth() >
                                      salesDate.start.getUTCMonth() &&
                                    date.getUTCFullYear() <=
                                      salesDate.end.getUTCFullYear() &&
                                    date.getUTCMonth() <
                                      salesDate.end.getUTCMonth()) ||
                                  (date.getUTCFullYear() >=
                                    salesDate.start.getUTCFullYear() &&
                                    date.getUTCMonth() >=
                                      salesDate.start.getUTCMonth() &&
                                    date.getUTCDate() >=
                                      salesDate.start.getUTCDate() &&
                                    date.getUTCFullYear() <=
                                      salesDate.end.getUTCFullYear() &&
                                    date.getUTCMonth() <=
                                      salesDate.end.getUTCMonth() &&
                                    date.getUTCDate() <=
                                      salesDate.end.getUTCDate() &&
                                    qua.customerId === oneCustomer.Id)
                                ) {
                                  data.push(qua);
                                }
                              });
                            }

                            setSales(data);
                          }}
                          variant="outline-info"
                        >
                          Search
                        </Button>
                      </center>
                    </Col>
                  </Form.Group>
                </Form>
                <hr />
                <Box
                  sx={{
                    height: 500,
                    width: "100%",
                    "& .super-app-theme--header": {
                      backgroundColor: "#1de5ec",
                      color: "white",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pageSize={5}
                    getRowId={(row) => row.Id}
                  />
                </Box>
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};
export default SalesReport;
