/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import {
  faPenToSquare,
  faTrashCan,
  faCalendar,
  faForwardStep,
  faBackwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  statusId: Yup.string().required("Status is required"),
  userId: Yup.string().required("User is required"),
  receiveDate: Yup.date().required("Date is required"),
});
const QualityCheckerPage = () => {
  const [open, setOpen] = useState(false);
  const [productions, setProductions] = useState([]);
  const [productionOrderRefs, setProductionOrderRefs] = useState([]);
  const [products, setProducts] = useState([]);
  const [types, setTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [productionOrders, setProductionOrders] = useState([]);
  const [productData, setProductData] = useState([]);
  const [qualityCheck, setQualityCheck] = useState([]);
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    console.log(roleData);
    try {
      const [
        production,
        productionOrderRef,
        product,
        type,
        qualityChecker,
        customer,
        productionOrder,
      ] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrderRef"
        ),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
        axios.get("http://garment.server.highendtradingplc.com/qualityChecker"),
        axios.get("http://garment.server.highendtradingplc.com/customer"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrder"
        ),
      ]);
      let data = [];
      production.data.map((p) => {
        const h = qualityChecker.data.find((q) => q.productionId === p.Id);

        if (!!!h && !!p?.endingDate) {
          data.push(p);
        }
      });
      setProductions(data);
      setProductionOrderRefs(productionOrderRef.data);
      setProducts(product.data);
      setTypes(type.data);
      setUsers(customer.data);
      setProductionOrders(productionOrder.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      productionId: "",
      receiveDate: "",
      userId: "",
      statusId: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios.post(
          "http://garment.server.highendtradingplc.com/qualityChecker/add",
          values
        );
        setOpen(false);
      } catch (error) {
        console.log(error);
      } finally {
        values.productionId = "";
        values.receiveDate = "";
        values.userId = "";
        values.statusId = "";
        fetchAllData();
      }
    },
  });

  const handleUpdate = (data) => {
    // formik.values.productionId = id;
    const production = productions.find((p) => p.Id === data.row.productionId);
    const productionOrder = productionOrders.filter(
      (p) => p.productionOrderRefId === production?.productionOrderRefId
    );
    console.log(production, data.row, productionOrder);
    setQualityCheck(production);
    setProductData(productionOrder);
    setOpen(true);
  };
  const handleOpen = () => {
    formik.values.productionId = "";
    formik.values.userId = "";
    formik.values.statusId = "";
    formik.values.receiveDate = "";
    setOpen(false);
  };
  const columns = [
    { field: "Id", headerName: "ID", width: 50 },
    { field: "fullName", headerName: "FullName", width: 150 },
    {
      field: "receiveDate",
      headerName: "Order Recieve Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "deadlineDate",
      headerName: "Order Deadline Date",
      minWidth: 145,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
    {
      field: "start",
      headerName: "Start Date",
      minWidth: 110,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
    {
      field: "end",
      headerName: "Raw Receive Date",
      minWidth: 140,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate() || "-"}/${
          new Date(params).getUTCMonth() + 1 || "-"
        }/${new Date(params).getUTCFullYear() || "-"}`,
    },
  ];
  const rows = productions?.map((production, index) => {
    const orders = productionOrderRefs.find(
      (order) => order.Id === production.productionOrderRefId
    );

    const customer = users.find((c) => c.Id == orders.customerId);

    return {
      Id: index + 1,
      id: orders?.Id,
      productionId: production?.Id,
      fullName: customer?.fullName,
      receiveDate: orders?.orderReceiveDate,
      deadlineDate: orders?.orderDeadlineDate,
      start: production?.startedDate || "",
      end: production?.materialReceiveDate || "",
    };
  });

  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "190px" }}>
        <center>
          <div className="" style={{ maxWidth: "1100px" }}>
            <div className="card card-info card-outline">
              <div className="card-header pl-2">
                {open && (
                  <Button
                    onClick={handleOpen}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                )}
              </div>
              <div className="card-body">
                <div>
                  <div
                    className="nav-bra pl-3 ml-1 p-2"
                    style={{
                      backgroundColor: "rgb(235, 235, 235)",
                      height: "40px",
                    }}
                  >
                    <span style={{ float: "left" }}>
                      Home / Quality Checker {open && "/ Insert"}
                    </span>
                  </div>
                  <hr />
                </div>
                {!open && (
                  <div>
                    <DataGrid
                      rows={rows}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      onCellClick={(row) => handleUpdate(row)}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...productions.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                  </div>
                )}
                {open && (
                  <div className=" ml-1 mr-1">
                    <div className="row">
                      {productData.map((rawOrder) => (
                        <div className="col-md-6 col-lg-4 mb-2 ">
                          <div
                            className="row p-3"
                            style={{
                              width: "290px",
                              borderRadius: "40px",
                              background: "rgb(235, 235, 235)",
                            }}
                          >
                            <div className="mt-3 col-6">
                              <img
                                src={`http://garment.server.highendtradingplc.com/${
                                  products.find(
                                    (p) => p.Id === rawOrder.productId
                                  ).photo
                                }`}
                                style={{
                                  width: "120px",
                                  height: "120px",
                                }}
                                alt="Photo"
                              />
                            </div>
                            <div className="mt-2 mb-3  col-6" style={{}}>
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-1:</b> <span>{rawOrder.scale1}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-2:</b> <span>{rawOrder.scale2}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-3:</b> <span>{rawOrder.scale3}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-4:</b> <span>{rawOrder.scale4}</span>
                              </div>
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>Scale-5:</b>{" "}
                                    <span>{rawOrder.scale5}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 2
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>Scale-6:</b>{" "}
                                    <span>{rawOrder.scale6}</span>
                                  </div>
                                </>
                              )}
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Quantity:</b>{" "}
                                <span>{rawOrder.quantity}</span>
                              </div>
                            </div>
                            <div style={{ float: "none" }} className="">
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 3
                              ) && (
                                <>
                                  <br />
                                </>
                              )}
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Name:</b>
                                <span className="">
                                  {
                                    products.find(
                                      (p) => p.Id === rawOrder.productId
                                    )?.name
                                  }
                                </span>
                              </div>
                              <br />
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Type:</b>{" "}
                                <span className="">
                                  {
                                    types.find(
                                      (t) =>
                                        t.Id ===
                                        products.find(
                                          (p) => p.Id === rawOrder.productId
                                        )?.typeId
                                    )?.typeName
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="row">
                      <div className="mt-2 col">
                        <Button
                          style={{
                            width: "100px",

                            height: "40px",
                          }}
                          variant="outline-success"
                          onClick={async (e) => {
                            try {
                              await axios.post(
                                "http://garment.server.highendtradingplc.com/qualityChecker/add",
                                {
                                  productionId: qualityCheck.Id,
                                  receiveDate: new Date(),
                                  userId: roleData.Id,
                                  statusId: 1,
                                }
                              );
                            } catch (error) {
                              console.log(error);
                            }
                            fetchAllData();
                            setOpen(false);
                          }}
                        >
                          <h6>Approve</h6>
                        </Button>
                      </div>
                      <div className="mt-2 col">
                        <Button
                          style={{
                            width: "100px",

                            height: "40px",
                          }}
                          variant="outline-danger"
                          onClick={async (e) => {
                            try {
                              await axios.post(
                                "http://garment.server.highendtradingplc.com/qualityChecker/add",
                                {
                                  productionId: qualityCheck.Id,
                                  receiveDate: new Date(),
                                  userId: roleData.Id,
                                  statusId: 2,
                                }
                              );
                            } catch (error) {
                              console.log(error);
                            }
                            fetchAllData();
                            setOpen(false);
                          }}
                        >
                          <h6>Reject</h6>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
};

export default QualityCheckerPage;
