/** @format */
import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
const validationSchema = Yup.object().shape({
  orderDeadlineDate: Yup.date().required("Date is required!"),
  receiveDate: Yup.date().required("Date is required!"),
  comment: Yup.string().required("Comment is required!"),
});
function SalesPage() {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [branchs, setColors] = useState([]);
  const [users, setUnits] = useState(null);
  const [productValue, setProductValue] = useState([]);
  const [productionOrders, setProductionOrder] = useState([]);
  const [salesRefs, setSalesRef] = useState([]);
  const [valids, setValid] = useState([0, 0, 0, 0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [types, setTypes] = useState([]);
  const [update, setUpdate] = useState({
    productId: "",
    quantity: "",
    price: "",
  });
  const [newSaleRef, setNewSalesRef] = useState([]);
  const [newProductionOrder, setNewProductionOrder] = useState([]);
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const roleData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };
  const fetchAllData = async () => {
    try {
      const [
        customer,
        product,
        user,
        branch,
        salesRef,
        productionOrder,
        qualityChecker,
        production,
        type,
      ] = await Promise.all([
        axios.get("http://garment.server.highendtradingplc.com/customer"),
        axios.get("http://garment.server.highendtradingplc.com/products"),
        axios.get("http://garment.server.highendtradingplc.com/user"),
        axios.get("http://garment.server.highendtradingplc.com/branch"),
        axios.get("http://garment.server.highendtradingplc.com/salesRef"),
        axios.get(
          "http://garment.server.highendtradingplc.com/productionOrder"
        ),
        axios.get("http://garment.server.highendtradingplc.com/qualityChecker"),
        axios.get("http://garment.server.highendtradingplc.com/production"),
        axios.get("http://garment.server.highendtradingplc.com/type"),
      ]);

      setCustomers(customer.data);
      setProducts(product.data);
      setColors(branch.data);
      setUnits(user.data);
      setTypes(type.data);
      const allSales = salesRef.data.filter(
        (s) => s.statusId === 1 && new Date() >= new Date(s.orderDeadlineDate)
      );
      let data = [];
      allSales.map((a) => {
        const p = production.data?.find(
          (pp) => pp.productionOrderRefId === a.productionOrderRefId
        );
        const q = qualityChecker.data?.find(
          (qq) => qq.productionId === p?.Id && qq.statusId === 1
        );
        if (!!q) {
          data.push(a);
        }
      });
      setSalesRef(data);
      setProductionOrder(productionOrder.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleUpdate = (id) => {
    setOpen(!open);
    const d = salesRefs.find((s) => s.Id === id);
    const da = productionOrders.filter(
      (f) => f.productionOrderRefId === d.productionOrderRefId
    );
    setNewProductionOrder(da);
    setNewSalesRef(d);
  };

  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "customerId",
      headerAlign: "center",
      align: "center",
      headerName: "Customer Name",
      minWidth: 150,
      valueGetter: (params) => customers.find((c) => c.Id === params)?.fullName,
    },

    {
      field: "branchId",
      headerAlign: "center",
      align: "center",
      headerName: "Branch",
      minWidth: 150,
      valueGetter: (params) => branchs.find((c) => c.Id === params)?.location,
    },
    {
      field: "totalPrice",
      headerAlign: "center",
      align: "center",
      headerName: "Total Price",
      minWidth: 100,
    },
    {
      field: "remainPrice",
      headerAlign: "center",
      align: "center",
      headerName: "Remain Price",
      minWidth: 100,
    },
    {
      field: "orderDeadlineDate",
      headerAlign: "center",
      align: "center",
      headerName: "Order Deadline",
      minWidth: 120,
      valueGetter: (params) =>
        `${new Date(params).getUTCDate()}/${
          new Date(params).getUTCMonth() + 1
        }/${new Date(params).getUTCFullYear()}`,
    },
  ];
  const columnsOrder = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 50,
    },
    {
      field: "product",
      headerClassName: "super-app-theme--header",
      headerName: "Product",
      width: 120,
    },
    {
      field: "type",
      headerClassName: "super-app-theme--header",
      headerName: "Type",
      width: 100,
    },
    {
      field: "scale1",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 1",
      width: 80,
    },
    {
      field: "scale2",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 2",
      width: 80,
    },
    {
      field: "scale3",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 3",
      width: 80,
    },
    {
      field: "scale4",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 4",
      width: 80,
    },
    {
      field: "scale5",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 5",
      width: 80,
    },
    {
      field: "scale6",
      headerClassName: "super-app-theme--header",
      headerName: "Scale 6",
      width: 80,
    },
    {
      field: "quantity",
      headerClassName: "super-app-theme--header",
      headerName: "Qty",
      width: 80,
    },
    {
      field: "unit",
      headerClassName: "super-app-theme--header",
      headerName: "U.Price",
      width: 100,
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "T.Price",
      width: 100,
    },
  ];

  const rowsOrder = newProductionOrder.map((prod) => {
    const product = products.find((p) => p.Id === prod.productId);
    const type = types.find((t) => t.Id === product.typeId);
    return {
      Id: prod.Id,
      product: product?.name,
      type: type?.typeName,
      scale1: prod?.scale1,
      scale2: prod?.scale2,
      scale3: prod?.scale3,
      scale4: prod?.scale4,
      scale5: prod?.scale5,
      scale6: prod?.scale6,
      quantity: prod?.quantity,
      unit: prod?.price,
      price: parseFloat(prod?.quantity) * parseFloat(prod?.price),
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>

      <main style={{ marginTop: "190px" }}>
        <center>
          <div style={{ maxWidth: "1100px" }}>
            <div className="card card-info card-outline">
              <div className="card-header">
                <h4 style={{ float: "left" }}>New Sales Entry</h4>
                {open && (
                  <Button
                    onClick={(e) => {
                      setOpen(false);
                    }}
                    variant="outline-success"
                    className="m-1"
                    style={{ float: "right" }}
                  >
                    <div
                      className="row"
                      style={{
                        marginBottom: "-10px",
                        marginLeft: "-10px",
                      }}
                    >
                      <div className="col">
                        <FontAwesomeIcon
                          style={{ float: "right", marginRight: "-1px" }}
                          icon={faBackwardStep}
                        />
                      </div>
                      <div className="col-7">
                        <h6
                          style={{
                            float: "left",
                            marginLeft: "-10px",
                          }}
                        >
                          Back
                        </h6>
                      </div>
                    </div>
                  </Button>
                )}
              </div>
              <div className="card-body">
                <div
                  className="nav-bra pl-3 ml-1 p-2 "
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                    height: "40px",
                  }}
                >
                  <span style={{ float: "left" }}> Sales / Create</span>
                </div>
                <hr />
                {!open && (
                  <div>
                    <DataGrid
                      rows={salesRefs}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columns}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...salesRefs.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      onCellClick={(row) => handleUpdate(row.id)}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                  </div>
                )}
                {open && (
                  <div>
                    <Row className="mb-2 ml-3">
                      <Col lg={3} style={{ width: "300px" }}>
                        <span style={{ float: "left" }}>
                          <b>Name :</b>{" "}
                          {
                            customers.find((c) => newSaleRef.customerId)
                              ?.fullName
                          }
                        </span>
                      </Col>
                      <Col lg={3} style={{ width: "300px" }}>
                        <span style={{ float: "left" }}>
                          <b>Branch :</b>{" "}
                          {
                            branchs.find((b) => b.Id === newSaleRef.branchId)
                              ?.location
                          }
                        </span>
                      </Col>
                      <Col lg={3} style={{ width: "300px" }}>
                        <span style={{ float: "left" }}>
                          <b>Total Price :</b> {newSaleRef.totalPrice}
                        </span>
                      </Col>
                      <Col lg={3} style={{ width: "300px" }}>
                        <span style={{ float: "left" }}>
                          <b>Remain Price :</b> {newSaleRef.remainPrice}
                        </span>
                      </Col>
                    </Row>
                    <div className="row">
                      {newProductionOrder.map((rawOrder) => (
                        <div className="col-md-6 col-lg-4 mb-2 ">
                          <div
                            className="row p-3"
                            style={{
                              width: "290px",
                              height: "250px",
                              borderRadius: "10px",
                              background: "rgb(235, 235, 235)",
                            }}
                          >
                            <div className="mt-3 col-6">
                              <img
                                src={`http://garment.server.highendtradingplc.com/${
                                  products.find(
                                    (p) => p.Id === rawOrder.productId
                                  ).photo
                                }`}
                                style={{
                                  width: "120px",
                                  height: "120px",
                                }}
                                alt="Photo"
                              />
                            </div>
                            <div className="mt-2 mb-3  col-6" style={{}}>
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-1:</b> <span>{rawOrder.scale1}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-2:</b> <span>{rawOrder.scale2}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-3:</b> <span>{rawOrder.scale3}</span>
                              </div>
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Scale-4:</b> <span>{rawOrder.scale4}</span>
                              </div>
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId &&
                                  (p.typeId === 2 || p.typeId === 1)
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>Scale-5:</b>{" "}
                                    <span>{rawOrder.scale5}</span>
                                  </div>
                                </>
                              )}
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 2
                              ) && (
                                <>
                                  <br />
                                  <div style={{ float: "left" }}>
                                    <b>Scale-6:</b>{" "}
                                    <span>{rawOrder.scale6}</span>
                                  </div>
                                </>
                              )}
                              <br />
                              <div style={{ float: "left" }}>
                                {" "}
                                <b>Quantity:</b>{" "}
                                <span>{rawOrder.quantity}</span>
                              </div>
                            </div>
                            <div style={{ float: "none" }} className="">
                              {!!products.find(
                                (p) =>
                                  p.Id === rawOrder.productId && p.typeId === 3
                              ) && (
                                <>
                                  <br />
                                </>
                              )}
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Name:</b>
                                <span className="">
                                  {
                                    products.find(
                                      (p) => p.Id === rawOrder.productId
                                    )?.name
                                  }
                                </span>
                              </div>
                              <br />
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "-20px",
                                }}
                                className=""
                              >
                                <b className="">Type:</b>{" "}
                                <span className="">
                                  {
                                    types.find(
                                      (t) =>
                                        t.Id ===
                                        products.find(
                                          (p) => p.Id === rawOrder.productId
                                        )?.typeId
                                    )?.typeName
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="mb-3 mt-3"
                      style={{ border: "solid 1px black" }}
                    >
                      {" "}
                    </div>
                    <DataGrid
                      rows={rowsOrder}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      columns={columnsOrder}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                      initialState={{
                        ...newProductionOrder.initialState,
                        pagination: {
                          paginationModel: { pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10, 15]}
                      getRowId={(row) => row.Id}
                    />
                    <Formik
                      initialValues={{
                        comment: "",
                        receiveDate: "",
                        price: "",
                      }}
                      validationSchema={Yup.object().shape({
                        comment: Yup.string()
                          .required("Comment is required!")
                          .min(5, "Too Short!")
                          .max(50, "Too Long!"),
                        price: Yup.number()
                          .min(
                            newSaleRef.remainPrice,
                            `The Remain Price is ${newSaleRef.remainPrice}`
                          )
                          .max(
                            newSaleRef.remainPrice,
                            `The Remain Price is ${newSaleRef.remainPrice}`
                          ),
                      })}
                      onSubmit={async (values) => {
                        try {
                          //newSaleRef.receiveDate = values.receiveDate;
                          newSaleRef.comment = values.comment;
                          newSaleRef.remainPrice = 0;
                          newSaleRef.statusId = 2;
                          newSaleRef.receiveDate = new Date();
                          newSaleRef.userId = roleData.Id;
                          await axios.put(
                            "http://garment.server.highendtradingplc.com/salesRef/update/" +
                              newSaleRef.Id,
                            newSaleRef
                          );
                          for (
                            let i = 0;
                            i <= newProductionOrder.length - 1;

                          ) {
                            await axios.post(
                              "http://garment.server.highendtradingplc.com/sales/add",
                              {
                                productId: newProductionOrder[i].productId,
                                salesRefId: newSaleRef.Id,
                                price: newProductionOrder[i].price,
                                quantity: newProductionOrder[i].quantity,
                              }
                            );
                            i = i + 1;
                          }
                          alert("Payment Finished Successfully!");
                          setOpen(false);
                        } catch (error) {
                          console.log(error);
                        } finally {
                          values.comment = "";
                          values.receiveDate = "";
                          values.price = "";
                          fetchAllData();
                        }
                      }}
                    >
                      {(props) => (
                        <Form className="m-2 " onSubmit={props.handleSubmit}>
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="formHorizontalUsername"
                          >
                            <Col lg={4}>
                              {newSaleRef?.remainPrice > 0 && (
                                <TextField
                                  id="outlined-basic"
                                  label="Price"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name="price"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.price}
                                  error={
                                    props.touched.price && props.errors.price
                                  }
                                  helperText={
                                    props.touched.price && props.errors.price
                                      ? `${props.errors.price}`
                                      : ""
                                  }
                                  style={{ width: "300px" }}
                                />
                              )}
                            </Col>
                            <Col lg={4}>
                              <TextField
                                id="outlined-basic"
                                label="Comment"
                                variant="outlined"
                                size="small"
                                multiline
                                type="text"
                                name="comment"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.comment}
                                error={
                                  props.touched.comment && props.errors.comment
                                }
                                helperText={
                                  props.touched.comment && props.errors.comment
                                    ? `${props.errors.comment}`
                                    : ""
                                }
                                style={{ width: "300px" }}
                              />
                            </Col>
                            <Col lg={4}>
                              <Button
                                style={{ width: "100px" }}
                                variant="outline-success"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </center>
      </main>
    </div>
  );
}
export default SalesPage;
